import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const storedAuth = sessionStorage.getItem('auth');
    if (storedAuth) {
      setAuth(JSON.parse(storedAuth));
    }
    setLoading(false);
  }, []);

  const value = {
    auth,
    setAuth,
    loading,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
/*import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState(null);  // Trạng thái xác thực
    const [loading, setLoading] = useState(true);  // Trạng thái đang tải

    useEffect(() => {
        const storedAuth = JSON.parse(sessionStorage.getItem('auth'));
        if (storedAuth) {
            setAuth(storedAuth);  // Thiết lập thông tin người dùng
        }
        setLoading(false);  // Kết thúc quá trình tải sau khi kiểm tra xong
    }, []);

    return (
        <AuthContext.Provider value={{ auth, setAuth, loading }}>
            {children}
        </AuthContext.Provider>
    );
};
*/
/*
import React, { createContext, useState, useEffect, useContext } from 'react';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(null);

  useEffect(() => {
    // Kiểm tra nếu `auth` đã được lưu trong sessionStorage
    const savedAuth = sessionStorage.getItem('auth');
    if (savedAuth) {
      setAuth(JSON.parse(savedAuth));
    }
  }, []);

  useEffect(() => {
    if (auth) {
      // Lưu `auth` vào sessionStorage mỗi khi nó thay đổi
      sessionStorage.setItem('auth', JSON.stringify(auth));
    } else {
      // Xóa `auth` khỏi sessionStorage nếu nó không tồn tại
      sessionStorage.removeItem('auth');
    }
  }, [auth]);

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};
*/

