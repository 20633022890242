import React, { useState, useEffect } from 'react';
import axios from './../../../api/api';
import { Button, Form, Table, Container, Modal } from 'react-bootstrap';

const RefereeListPage = () => {
    const [referees, setReferees] = useState([]);
    const [sports, setSports] = useState([]); // Fetch sports for the dropdown
    const [newReferee, setNewReferee] = useState({
        sportId: '',
        nick: '',
        fullName: '',
        avatar: '',
        phoneNumber: '',
        zaloLink: '',
        facebookLink: '',
        email: '',
        description: ''
    });
    const [editingReferee, setEditingReferee] = useState(null);
    const [showModal, setShowModal] = useState(false); // Modal state
    const [deleteId, setDeleteId] = useState(null); // ID for delete confirmation

    useEffect(() => {
        fetchReferees();
        fetchSports(); // Fetch available sports for the dropdown
    }, []);

    const fetchReferees = async () => {
        const response = await axios.get('/referees');
        setReferees(response.data);
    };

    const fetchSports = async () => {
        const response = await axios.get('/sports');
        setSports(response.data);
    };

    // Save or update referee
    const handleSaveReferee = async () => {
        if (editingReferee) {
            await axios.put(`/referees/${editingReferee._id}`, editingReferee);
        } else {
            await axios.post('/referees', newReferee);
            setNewReferee({
                sportId: '',
                nick: '',
                fullName: '',
                avatar: '',
                phoneNumber: '',
                zaloLink: '',
                facebookLink: '',
                email: '',
                description: ''
            });
        }
        setShowModal(false);
        setEditingReferee(null);
        fetchReferees();
    };

    // Delete referee
    const handleDeleteReferee = async (id) => {
        await axios.delete(`/referees/${id}`);
        setDeleteId(null);
        fetchReferees();
    };

    // Open modal for create or edit
    const openModal = (referee = null) => {
        if (referee) {
            setEditingReferee({
                ...referee,
                sportId: referee.sportId._id || referee.sportId // Make sure sportId is correct
            });
        } else {
            setNewReferee({
                sportId: '',
                nick: '',
                fullName: '',
                avatar: '',
                phoneNumber: '',
                zaloLink: '',
                facebookLink: '',
                email: '',
                description: ''
            });
        }
        setShowModal(true);
    };

    // Close modal
    const closeModal = () => {
        setEditingReferee(null);
        setShowModal(false);
    };

    // Handle file upload
    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('avatar', file); // 'avatar' is the field name for the backend

        try {
            const response = await axios.post('/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            const fileUrl = response.data.url;
            if (editingReferee) {
                setEditingReferee({ ...editingReferee, avatar: fileUrl });
            } else {
                setNewReferee({ ...newReferee, avatar: fileUrl });
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    // Confirm delete
    const confirmDelete = (id) => {
        setDeleteId(id);
    };

    return (
        <div>
            <h2 className="my-4">Referee Management</h2>

            <Button variant="primary" className="mb-4" onClick={() => openModal()}>Create New Referee</Button>

            <h3>Existing Referees</h3>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Nick</th>
                        <th>Avatar</th>
                        <th>Full Name</th>
                        <th>Phone Number</th>
                        <th>Sport</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {referees.map((referee) => (
                        <tr key={referee._id}>
                            <td>{referee.nick}</td>
                            <td>
                                <img src={`${process.env.REACT_APP_API_BASE_URL}/${referee.avatar}`} alt="Avatar" width="100" /> {/* Render avatar */}
                            </td>
                            <td>{referee.fullName}</td>
                            <td>{referee.phoneNumber}</td>
                            <td>{referee.sportId.name}</td>
                            <td>
                                <Button
                                    variant="warning"
                                    className="me-2"
                                    onClick={() => openModal(referee)}
                                >
                                    Edit
                                </Button>
                                <Button
                                    variant="danger"
                                    onClick={() => confirmDelete(referee._id)}
                                >
                                    Delete
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {/* Modal for create/edit referee */}
            <Modal show={showModal} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{editingReferee ? 'Edit Referee' : 'Create New Referee'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Select Sport</Form.Label>
                            <Form.Control
                                as="select"
                                value={editingReferee ? editingReferee.sportId : newReferee.sportId}
                                onChange={(e) => {
                                    const sportId = e.target.value;
                                    if (editingReferee) {
                                        setEditingReferee({ ...editingReferee, sportId });
                                    } else {
                                        setNewReferee({ ...newReferee, sportId });
                                    }
                                }}
                            >
                                <option value="">Select a Sport</option>
                                {sports.map((sport) => (
                                    <option key={sport._id} value={sport._id}>
                                        {sport.name}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Nick</Form.Label>
                            <Form.Control
                                type="text"
                                value={editingReferee ? editingReferee.nick : newReferee.nick}
                                onChange={(e) => {
                                    if (editingReferee) {
                                        setEditingReferee({ ...editingReferee, nick: e.target.value });
                                    } else {
                                        setNewReferee({ ...newReferee, nick: e.target.value });
                                    }
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Full Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={editingReferee ? editingReferee.fullName : newReferee.fullName}
                                onChange={(e) => {
                                    if (editingReferee) {
                                        setEditingReferee({ ...editingReferee, fullName: e.target.value });
                                    } else {
                                        setNewReferee({ ...newReferee, fullName: e.target.value });
                                    }
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control
                                type="text"
                                value={editingReferee ? editingReferee.phoneNumber : newReferee.phoneNumber}
                                onChange={(e) => {
                                    if (editingReferee) {
                                        setEditingReferee({ ...editingReferee, phoneNumber: e.target.value });
                                    } else {
                                        setNewReferee({ ...newReferee, phoneNumber: e.target.value });
                                    }
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                value={editingReferee ? editingReferee.email : newReferee.email}
                                onChange={(e) => {
                                    if (editingReferee) {
                                        setEditingReferee({ ...editingReferee, email: e.target.value });
                                    } else {
                                        setNewReferee({ ...newReferee, email: e.target.value });
                                    }
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Avatar</Form.Label>
                            <Form.Control type="file" onChange={handleFileChange} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                type="text"
                                value={editingReferee ? editingReferee.description : newReferee.description}
                                onChange={(e) => {
                                    if (editingReferee) {
                                        setEditingReferee({ ...editingReferee, description: e.target.value });
                                    } else {
                                        setNewReferee({ ...newReferee, description: e.target.value });
                                    }
                                }}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>Close</Button>
                    <Button variant="primary" onClick={handleSaveReferee}>
                        {editingReferee ? 'Save Changes' : 'Create Referee'}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal for delete confirmation */}
            <Modal show={!!deleteId} onHide={() => setDeleteId(null)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this referee?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setDeleteId(null)}>Cancel</Button>
                    <Button variant="danger" onClick={() => handleDeleteReferee(deleteId)}>Delete</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default RefereeListPage;
