import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams để lấy articleId từ URL
import ReactQuill from 'react-quill';  // Import React Quill cho việc soạn thảo HTML
import 'react-quill/dist/quill.snow.css';  // Import style của Quill
import axios from './../../../api/api';  // Import API
import { Button, Form, Table, Container, Modal } from 'react-bootstrap';  // Import các component từ react-bootstrap

const ArticleContentListPage = () => {
    const { articleId } = useParams();  // Lấy articleId từ URL
    const [contents, setContents] = useState([]);  // Quản lý danh sách nội dung bài viết
    const [newContent, setNewContent] = useState({ contentTitle: '', contentType: 'text', contentValue: '', displayOrder: 0 });  // Trạng thái cho nội dung mới
    const [editingContent, setEditingContent] = useState(null);  // Trạng thái cho nội dung đang chỉnh sửa
    const [showModal, setShowModal] = useState(false);  // Quản lý trạng thái Modal

    // Gọi API để lấy danh sách nội dung của bài viết
    useEffect(() => {
        fetchContents();
    }, []);

    const fetchContents = async () => {
        const response = await axios.get(`/articles/${articleId}/contents`);
        setContents(response.data);
    };

    // Lưu nội dung mới hoặc chỉnh sửa nội dung hiện tại
    const handleSaveContent = async () => {
        if (editingContent) {
            await axios.put(`/articles/contents/${editingContent._id}`, editingContent);
        } else {
            await axios.post(`/articles/${articleId}/contents`, newContent);
            setNewContent({ contentTitle: '', contentType: 'text', contentValue: '', displayOrder: 0 });
        }
        setShowModal(false);
        setEditingContent(null);
        fetchContents();
    };

    // Xử lý upload file hình ảnh
    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('image', file);

        try {
            const response = await axios.post('/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            const fileUrl = response.data.url;
            setNewContent({ ...newContent, contentValue: fileUrl });
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    // Hiển thị các trường tùy theo contentType
    const renderContentValueField = () => {
        switch (newContent.contentType || editingContent?.contentType) {
            case 'image':
                return (
                    <Form.Group className="mb-3">
                        <Form.Label>Upload Image</Form.Label>
                        <Form.Control type="file" onChange={handleFileChange} />
                        {editingContent?.contentValue && <img src={editingContent.contentValue} alt="Uploaded" width="100" />}
                    </Form.Group>
                );
            case 'html':
                return (
                    <Form.Group className="mb-3">
                        <Form.Label>HTML Content</Form.Label>
                        <ReactQuill 
                            value={editingContent ? editingContent.contentValue : newContent.contentValue} 
                            onChange={(content) => {
                                if (editingContent) {
                                    setEditingContent({ ...editingContent, contentValue: content });
                                } else {
                                    setNewContent({ ...newContent, contentValue: content });
                                }
                            }} 
                        />
                    </Form.Group>
                );
            default:
                return (
                    <Form.Group className="mb-3">
                        <Form.Label>Text or Video URL</Form.Label>
                        <Form.Control 
                            type="text" 
                            value={editingContent ? editingContent.contentValue : newContent.contentValue} 
                            onChange={(e) => {
                                if (editingContent) {
                                    setEditingContent({ ...editingContent, contentValue: e.target.value });
                                } else {
                                    setNewContent({ ...newContent, contentValue: e.target.value });
                                }
                            }} 
                        />
                    </Form.Group>
                );
        }
    };

    // Xác nhận xóa nội dung
    const handleDeleteContent = async (contentId) => {
        await axios.delete(`/articles/contents/${contentId}`);
        fetchContents();
    };

    // Mở Modal thêm hoặc sửa nội dung
    const openModal = (content = null) => {
        setEditingContent(content);
        setNewContent(content || { contentTitle: '', contentType: 'text', contentValue: '', displayOrder: 0 });
        setShowModal(true);
    };

    // Đóng Modal
    const closeModal = () => {
        setEditingContent(null);
        setShowModal(false);
    };

    return (
        <Container>
            <h2 className="my-4">Manage Article Contents</h2>

            <Button variant="primary" className="mb-4" onClick={() => openModal()}>Add New Content</Button>

            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Type</th>
                        <th>Value</th>
                        <th>Display Order</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {contents.map((content) => (
                        <tr key={content._id}>
                            <td>{content.contentTitle}</td>
                            <td>{content.contentType}</td>
                            <td>{content.contentValue}</td>
                            <td>{content.displayOrder}</td>
                            <td>
                                <Button variant="warning" className="me-2" onClick={() => openModal(content)}>Edit</Button>
                                <Button variant="danger" onClick={() => handleDeleteContent(content._id)}>Delete</Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {/* Modal thêm/sửa nội dung */}
            <Modal show={showModal} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{editingContent ? 'Edit Content' : 'Add New Content'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Title</Form.Label>
                            <Form.Control 
                                type="text" 
                                value={editingContent ? editingContent.contentTitle : newContent.contentTitle} 
                                onChange={(e) => {
                                    if (editingContent) {
                                        setEditingContent({ ...editingContent, contentTitle: e.target.value });
                                    } else {
                                        setNewContent({ ...newContent, contentTitle: e.target.value });
                                    }
                                }} 
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Content Type</Form.Label>
                            <Form.Control 
                                as="select" 
                                value={editingContent ? editingContent.contentType : newContent.contentType} 
                                onChange={(e) => {
                                    if (editingContent) {
                                        setEditingContent({ ...editingContent, contentType: e.target.value });
                                    } else {
                                        setNewContent({ ...newContent, contentType: e.target.value });
                                    }
                                }}>
                                <option value="text">Text</option>
                                <option value="html">HTML</option>
                                <option value="image">Image</option>
                                <option value="video">Video (YouTube)</option>
                            </Form.Control>
                        </Form.Group>

                        {renderContentValueField()}

                        <Form.Group className="mb-3">
                            <Form.Label>Display Order</Form.Label>
                            <Form.Control 
                                type="number" 
                                value={editingContent ? editingContent.displayOrder : newContent.displayOrder} 
                                onChange={(e) => {
                                    if (editingContent) {
                                        setEditingContent({ ...editingContent, displayOrder: e.target.value });
                                    } else {
                                        setNewContent({ ...newContent, displayOrder: e.target.value });
                                    }
                                }} 
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>Close</Button>
                    <Button variant="primary" onClick={handleSaveContent}>
                        {editingContent ? 'Save Changes' : 'Add Content'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default ArticleContentListPage;


/*import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams để lấy articleId từ URL

import axios from './../../../api/api';
import { Button, Form, Table, Container, Modal } from 'react-bootstrap';

const ArticleContentListPage = () => {
    const { articleId } = useParams();  // Lấy articleId từ URL
    const [contents, setContents] = useState([]);
    const [newContent, setNewContent] = useState({ contentTitle: '', contentType: 'text', contentValue: '', displayOrder: 0 });
    const [editingContent, setEditingContent] = useState(null);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        console.log("ArticleContentListPage");
        fetchContents();
    }, []);

    const fetchContents = async () => {
        const response = await axios.get(`/articles/${articleId}/contents`);
        setContents(response.data);
    };

    const handleSaveContent = async () => {
        if (editingContent) {
            await axios.put(`/contents/${editingContent._id}`, editingContent);
        } else {
            await axios.post(`/articles/${articleId}/contents`, newContent);
            setNewContent({ contentTitle: '', contentType: 'text', contentValue: '', displayOrder: 0 });
        }
        setShowModal(false);
        setEditingContent(null);
        fetchContents();
    };

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('image', file);

        try {
            const response = await axios.post('/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            const fileUrl = response.data.url;
            setNewContent({ ...newContent, contentValue: fileUrl });
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    const renderContentValueField = () => {
        switch (newContent.contentType || editingContent?.contentType) {
            case 'image':
                return (
                    <Form.Group className="mb-3">
                        <Form.Label>Upload Image</Form.Label>
                        <Form.Control type="file" onChange={handleFileChange} />
                        {editingContent?.contentValue && <img src={editingContent.contentValue} alt="Uploaded" width="100" />}
                    </Form.Group>
                );
            case 'html':
                return (
                    <Form.Group className="mb-3">
                        <Form.Label>HTML Content</Form.Label>
                        <Form.Control as="textarea" rows={5} value={editingContent ? editingContent.contentValue : newContent.contentValue} onChange={(e) => {
                            if (editingContent) {
                                setEditingContent({ ...editingContent, contentValue: e.target.value });
                            } else {
                                setNewContent({ ...newContent, contentValue: e.target.value });
                            }
                        }} />
                    </Form.Group>
                );
            default:
                return (
                    <Form.Group className="mb-3">
                        <Form.Label>Text or Video URL</Form.Label>
                        <Form.Control type="text" value={editingContent ? editingContent.contentValue : newContent.contentValue} onChange={(e) => {
                            if (editingContent) {
                                setEditingContent({ ...editingContent, contentValue: e.target.value });
                            } else {
                                setNewContent({ ...newContent, contentValue: e.target.value });
                            }
                        }} />
                    </Form.Group>
                );
        }
    };

    return (
        <Container>
            <h2 className="my-4">Manage Article Contents</h2>

            <Button variant="primary" className="mb-4" onClick={() => setShowModal(true)}>Add New Content</Button>

            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Type</th>
                        <th>Value</th>
                        <th>Display Order</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {contents.map((content) => (
                        <tr key={content._id}>
                            <td>{content.contentTitle}</td>
                            <td>{content.contentType}</td>
                            <td>{content.contentValue}</td>
                            <td>{content.displayOrder}</td>
                            <td>
                                <Button variant="warning" className="me-2" onClick={() => setEditingContent(content)}>Edit</Button>
                                <Button variant="danger" onClick={() => axios.delete(`/contents/${content._id}`).then(fetchContents)}>Delete</Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>


            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{editingContent ? 'Edit Content' : 'Add New Content'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Title</Form.Label>
                            <Form.Control type="text" value={editingContent ? editingContent.contentTitle : newContent.contentTitle} onChange={(e) => {
                                if (editingContent) {
                                    setEditingContent({ ...editingContent, contentTitle: e.target.value });
                                } else {
                                    setNewContent({ ...newContent, contentTitle: e.target.value });
                                }
                            }} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Content Type</Form.Label>
                            <Form.Control as="select" value={editingContent ? editingContent.contentType : newContent.contentType} onChange={(e) => {
                                if (editingContent) {
                                    setEditingContent({ ...editingContent, contentType: e.target.value });
                                } else {
                                    setNewContent({ ...newContent, contentType: e.target.value });
                                }
                            }}>
                                <option value="text">Text</option>
                                <option value="html">HTML</option>
                                <option value="image">Image</option>
                                <option value="video">Video (YouTube)</option>
                            </Form.Control>
                        </Form.Group>

                        {renderContentValueField()}

                        <Form.Group className="mb-3">
                            <Form.Label>Display Order</Form.Label>
                            <Form.Control type="number" value={editingContent ? editingContent.displayOrder : newContent.displayOrder} onChange={(e) => {
                                if (editingContent) {
                                    setEditingContent({ ...editingContent, displayOrder: e.target.value });
                                } else {
                                    setNewContent({ ...newContent, displayOrder: e.target.value });
                                }
                            }} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
                    <Button variant="primary" onClick={handleSaveContent}>
                        {editingContent ? 'Save Changes' : 'Add Content'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default ArticleContentListPage;
*/