import React, { useState, useEffect } from 'react';
import axios from './../../../api/api';
import { Button, Form, Table, Container, Modal } from 'react-bootstrap';

const ManagerModal = ({ stadiumId, show, handleClose }) => {
    const [managers, setManagers] = useState([]);
    const [nonManagers, setNonManagers] = useState([]);
    const [selectedUser, setSelectedUser] = useState('');

    useEffect(() => {
        if (stadiumId) {
            fetchManagers();
            fetchNonManagers();
        }
    }, [stadiumId]);

    const fetchManagers = async () => {
        try {
            const response = await axios.get(`/stadiums/${stadiumId}/managers`);
            setManagers(response.data);
        } catch (error) {
            console.error('Error fetching managers:', error);
        }
    };

    const fetchNonManagers = async () => {
        try {
            const response = await axios.get('/users');  // Assuming you have an endpoint for all users
            const nonManagerUsers = response.data.filter(
                (user) => !managers.find((manager) => manager._id === user._id)
            );
            setNonManagers(nonManagerUsers);
        } catch (error) {
            console.error('Error fetching non-managers:', error);
        }
    };


    const handleAddManager = async () => {
        try {
            await axios.post(`/stadiums/${stadiumId}/add-manager`, { managerId: selectedUser });
            fetchManagers(); // Refresh managers list after adding
            fetchNonManagers(); // Refresh non-managers list
        } catch (error) {
            console.error('Error adding manager:', error);
        }
    };

    const handleRemoveManager = async (managerId) => {
        try {
            await axios.delete(`/stadiums/${stadiumId}/remove-manager/${managerId}`);
            fetchManagers(); // Refresh managers list after removing
        } catch (error) {
            console.error('Error removing manager:', error);
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Manage Stadium Managers</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5>Current Managers</h5>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {managers.map((manager) => (
                            <tr key={manager._id}>
                                <td>{manager.name}</td>
                                <td>{manager.email}</td>
                                <td>
                                    <Button
                                        variant="danger"
                                        onClick={() => handleRemoveManager(manager._id)}
                                    >
                                        Remove
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

                <h5>Add New Manager</h5>
                <Form.Group>
                    <Form.Label>Select User</Form.Label>
                    <Form.Control
                        as="select"
                        value={selectedUser}
                        onChange={(e) => setSelectedUser(e.target.value)}
                    >
                        <option value="">Select a user</option>
                        {nonManagers.map((user) => (
                            <option key={user._id} value={user._id}>
                                {user.name} ({user.email})
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>
                <Button variant="primary" onClick={handleAddManager} disabled={!selectedUser}>
                    Add Manager
                </Button>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};


const StadiumManagementListPage = () => {
    const [stadiums, setStadiums] = useState([]);
    const [sports, setSports] = useState([]);
    const [selectedStadiumId, setSelectedStadiumId] = useState(null);
    const [showManagerModal, setShowManagerModal] = useState(false);
    
    const [newStadium, setNewStadium] = useState({
        sportId: '',
        stadiumName: '',
        address: '',
        googleMapLink: '',
        contactPhone: '',
        avatar: '',
        youtubeLink: '',
        representativeName: '',
        introduction: '',
        pricingTable: ''
    });
    const [editingStadium, setEditingStadium] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [deleteId, setDeleteId] = useState(null);  // ID để xác nhận xóa

    // Fetch stadiums and sports when the component loads
    useEffect(() => {
        fetchStadiums();
        fetchSports();  // Fetch the sports list for the dropdown
    }, []);

    const fetchStadiums = async () => {
        try {
            const response = await axios.get('/stadiums');
            setStadiums(response.data);
        } catch (error) {
            console.error("Error fetching stadiums:", error);
        }
    };

    const fetchSports = async () => {
        try {
            const response = await axios.get('/sports');
            setSports(response.data);
        } catch (error) {
            console.error("Error fetching sports:", error);
        }
    };
    const openManagerModal = (stadiumId) => {
        setSelectedStadiumId(stadiumId);
        setShowManagerModal(true);
    };

    const closeManagerModal = () => {
        setShowManagerModal(false);
        setSelectedStadiumId(null);
    };


    // Save or update stadium
    const handleSaveStadium = async () => {
        try {
            if (editingStadium && editingStadium._id) {
                // PUT to update stadium
                await axios.put(`/stadiums/${editingStadium._id}`, editingStadium);
            } else {
                // POST to create new stadium
                await axios.post('/stadiums', newStadium);
            }
            setShowModal(false);  // Close modal after saving
            fetchStadiums();  // Refresh stadiums list
        } catch (error) {
            console.error("Error saving stadium:", error);
        }
    };

    // Delete stadium
    const handleDeleteStadium = async (id) => {
        try {
            await axios.delete(`/stadiums/${id}`);
            setDeleteId(null);
            fetchStadiums();
        } catch (error) {
            console.error("Error deleting stadium:", error);
        }
    };

    // Open modal for creating or editing stadium
    const openModal = (stadium = null) => {
        if (stadium) {
            setEditingStadium(stadium);
        } else {
            setEditingStadium(null);
            setNewStadium({
                sportId: '',
                stadiumName: '',
                address: '',
                googleMapLink: '',
                contactPhone: '',
                avatar: '',
                youtubeLink: '',
                representativeName: '',
                introduction: '',
                pricingTable: ''
            });
        }
        setShowModal(true);
    };

    // Close modal
    const closeModal = () => {
        setEditingStadium(null);
        setShowModal(false);
    };

    // Handle file upload for avatar
    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('avatar', file);

        try {
            const response = await axios.post('/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            const fileUrl = response.data.url;
            if (editingStadium) {
                setEditingStadium((prev) => ({ ...prev, avatar: fileUrl }));
            } else {
                setNewStadium((prev) => ({ ...prev, avatar: fileUrl }));
            }
        } catch (error) {
            console.error('Error uploading avatar:', error);
        }
    };

    return (
        <Container>
            <h2 className="my-4">Stadium Management</h2>

            <Button variant="primary" className="mb-4" onClick={() => openModal()}>
                Create New Stadium
            </Button>

            <h3>Existing Stadiums</h3>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Sport</th>
                        <th>Stadium Name</th>
                        <th>Address</th>
                        <th>Contact Phone</th>
                        <th>Representative</th>
                        <th>Avatar</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {stadiums.map((stadium) => (
                        <tr key={stadium._id}>
                            <td>{stadium.sportId.name}</td>
                            <td>{stadium.stadiumName}</td>
                            <td>{stadium.address}</td>
                            <td>{stadium.contactPhone}</td>
                            <td>{stadium.representativeName}</td>
                            <td>
                                {stadium.avatar && (
                                    <img src={`${process.env.REACT_APP_API_BASE_URL}/${stadium.avatar}`} alt="Avatar" width="100" />
                                )}
                            </td>
                            <td>
                                <Button
                                    variant="warning"
                                    className="me-2"
                                    onClick={() => openModal(stadium)}
                                >
                                    Edit
                                </Button>
                                <Button
                                    variant="danger"
                                    onClick={() => setDeleteId(stadium._id)}
                                >
                                    Delete
                                </Button>
                                <Button
                                    variant="info"
                                    onClick={() => openManagerModal(stadium._id)}
                                >
                                    Manage Managers
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>

            </Table>
            {selectedStadiumId && (
                <ManagerModal
                    stadiumId={selectedStadiumId}
                    show={showManagerModal}
                    handleClose={closeManagerModal}
                />
            )}

            {/* Modal for creating/editing stadium */}
            <Modal show={showModal} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{editingStadium ? 'Edit Stadium' : 'Create New Stadium'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Select Sport</Form.Label>
                            <Form.Control
                                as="select"
                                value={editingStadium ? editingStadium.sportId._id || editingStadium.sportId : newStadium.sportId}
                                onChange={(e) => {
                                    const sportId = e.target.value;
                                    if (editingStadium) {
                                        setEditingStadium((prev) => ({ ...prev, sportId }));
                                    } else {
                                        setNewStadium((prev) => ({ ...prev, sportId }));
                                    }
                                }}
                            >
                                <option value="">Select a Sport</option>
                                {sports.map((sport) => (
                                    <option key={sport._id} value={sport._id}>
                                        {sport.name}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Stadium Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={editingStadium ? editingStadium.stadiumName : newStadium.stadiumName}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (editingStadium) {
                                        setEditingStadium((prev) => ({ ...prev, stadiumName: value }));
                                    } else {
                                        setNewStadium((prev) => ({ ...prev, stadiumName: value }));
                                    }
                                }}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                                type="text"
                                value={editingStadium ? editingStadium.address : newStadium.address}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (editingStadium) {
                                        setEditingStadium((prev) => ({ ...prev, address: value }));
                                    } else {
                                        setNewStadium((prev) => ({ ...prev, address: value }));
                                    }
                                }}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Contact Phone</Form.Label>
                            <Form.Control
                                type="text"
                                value={editingStadium ? editingStadium.contactPhone : newStadium.contactPhone}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (editingStadium) {
                                        setEditingStadium((prev) => ({ ...prev, contactPhone: value }));
                                    } else {
                                        setNewStadium((prev) => ({ ...prev, contactPhone: value }));
                                    }
                                }}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Representative Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={editingStadium ? editingStadium.representativeName : newStadium.representativeName}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (editingStadium) {
                                        setEditingStadium((prev) => ({ ...prev, representativeName: value }));
                                    } else {
                                        setNewStadium((prev) => ({ ...prev, representativeName: value }));
                                    }
                                }}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Avatar</Form.Label>
                            <Form.Control type="file" onChange={handleFileChange} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>YouTube Link</Form.Label>
                            <Form.Control
                                type="text"
                                value={editingStadium ? editingStadium.youtubeLink : newStadium.youtubeLink}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (editingStadium) {
                                        setEditingStadium((prev) => ({ ...prev, youtubeLink: value }));
                                    } else {
                                        setNewStadium((prev) => ({ ...prev, youtubeLink: value }));
                                    }
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Introduction</Form.Label>
                            <Form.Control
                                as="textarea"
                                value={editingStadium ? editingStadium.introduction : newStadium.introduction}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (editingStadium) {
                                        setEditingStadium((prev) => ({ ...prev, introduction: value }));
                                    } else {
                                        setNewStadium((prev) => ({ ...prev, introduction: value }));
                                    }
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Pricing Table (HTML)</Form.Label>
                            <Form.Control
                                as="textarea"
                                value={editingStadium ? editingStadium.pricingTable : newStadium.pricingTable}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (editingStadium) {
                                        setEditingStadium((prev) => ({ ...prev, pricingTable: value }));
                                    } else {
                                        setNewStadium((prev) => ({ ...prev, pricingTable: value }));
                                    }
                                }}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>Close</Button>
                    <Button variant="primary" onClick={handleSaveStadium}>
                        {editingStadium ? 'Save Changes' : 'Create Stadium'}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Delete confirmation modal */}
            <Modal show={!!deleteId} onHide={() => setDeleteId(null)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this stadium?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setDeleteId(null)}>Cancel</Button>
                    <Button variant="danger" onClick={() => handleDeleteStadium(deleteId)}>Delete</Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default StadiumManagementListPage;
