import React from 'react';
import { Routes, Route } from 'react-router-dom';

import ClubMemberManagement from '../admin/Club/ClubMemberManagement';
import ClubPracticeSessionManagement from '../admin/Club/ClubPracticeSessionManagement';
import PlaygroundManagementListPage from '../admin/Stadium/PlaygroundManagementListPage';
import SlotManagement from '../admin/Stadium/SlotManagement';


import Dashboard from '../admin/Dashboard';  // Import Dashboard
import Settings from '../admin/Settings';  // Import Settings
import UserManagement from '../admin/UserManagement';  // Import User Management
import SportListPage from '../admin/Sports/SportListPage';  // Import  SportListPage
import ArticleListPage from '../admin/Article/ArticleListPage';  // Import User Management
import ArticleContentListPage from '../admin/Article/ArticleContentListPage';  // Import User Management
import CoachListPage from '../admin/Coach/CoachListPage';  // Import User Management
import ClubListPage from '../admin/Club/ClubListPage';  // Import User Management
import RefereeListPage from '../admin/Referee/RefereeListPage';  // Import User Management
import VideoManagement from '../admin/Video/VideoManagement';  // Import User Management
import ClubManagement from '../admin/Club/ClubManagement';  // Import User Management
import MyClubPage from '../admin/Club/MyClubPage';  // Import User Management

import TournamentListPage from '../admin/Tournament/TournamentListPage';  // Import User Management
import StadiumManagementListPage from '../admin/Stadium/StadiumManagementListPage';  // Import User Management
import ClubRegistrationManagement from '../admin/Club/ClubRegistrationManagement';  // Import User Management
import ConfigListPage from '../admin/Config/ConfigListPage';  // Import User Management
import Profile from '../admin/User/Profile';  // Import User Management
import CustomerListPage from '../admin/Customer/CustomerListPage';  // Import User Management
import UserManagementForm from '../admin/User/UserManagementForm';  // Import User Management

import ChangePassword from '../../components/ChangePassword';  // Import User Management

function CommunityLayout() {
    return (

        <div>
            <Routes>
                <Route path="/club-members" element={<ClubMemberManagement />} />
                <Route path="/club-practices" element={<ClubPracticeSessionManagement />} />
                <Route path="/playgrouds" element={<PlaygroundManagementListPage />} />
                <Route path="/slots" element={<SlotManagement />} />


                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/configurations" element={<ConfigListPage />} />
                <Route path="/users-management" element={<UserManagementForm />} />
                <Route path="/sports" element={<SportListPage />} />
                <Route path="/articles" element={<ArticleListPage />} />
                <Route path="/coaches" element={<CoachListPage />} />
                <Route path="/clubs" element={<ClubListPage />} />
                <Route path="/customers" element={<CustomerListPage />} />
                <Route path="/my-profile" element={<Profile />} />
                <Route path="/my-clubs" element={<MyClubPage />} />
                <Route path="/manage-clubs" element={<ClubManagement />} />

                <Route path="/club-registration" element={<ClubRegistrationManagement />} />
                <Route path="/referees" element={<RefereeListPage />} />
                <Route path="/videos" element={<VideoManagement />} />
                <Route path="/tournaments" element={<TournamentListPage />} />
                <Route path="/stadiums" element={<StadiumManagementListPage />} />
                <Route path="/change-password" element={<ChangePassword />} />
                <Route path="/articles/:articleId/contents" element={<ArticleContentListPage />} />
                {/* Thêm các routes khác ở đây */}
            </Routes>
        </div>

    );
}

export default CommunityLayout;
