import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';  // Import useAuth để lấy hàm setAuth

const AdminHeader = () => {
    const { setAuth } = useAuth();  // Lấy setAuth để reset trạng thái đăng nhập
    const navigate = useNavigate();

    const handleLogout = () => {
        // Xóa thông tin đăng nhập từ sessionStorage và localStorage
        sessionStorage.removeItem('auth');
        localStorage.removeItem('username');
        localStorage.removeItem('role');

        // Reset trạng thái auth
        setAuth(null);

        // Điều hướng người dùng trở về trang đăng nhập
        navigate('/login');
    };

    return (
        <header style={headerStyle}>
            <h1 style={{ margin: 0 }}>CLUBHUB</h1>
            <button onClick={handleLogout} style={logoutButtonStyle}>
                Logout
            </button>
        </header>
    );
};

const headerStyle = {
    backgroundColor: '#007bff',
    color: 'white',
    padding: '10px',
    display: 'flex',  // Sử dụng flexbox để căn giữa nội dung
    justifyContent: 'space-between',  // Dàn đều không gian giữa tiêu đề và nút logout
    alignItems: 'center',  // Căn giữa theo trục dọc
};

const logoutButtonStyle = {
    backgroundColor: '#ff4d4d',
    color: 'white',
    border: 'none',
    padding: '10px',
    cursor: 'pointer',
    borderRadius: '5px',
};

export default AdminHeader;
