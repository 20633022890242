import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import './Header.css';
import { getMenuItems } from './Menu/Menuservice'; // Import MenuService

function Header() {
    const [menuVisible, setMenuVisible] = useState(false); // State để ẩn/hiện menu
    const [menuItems, setMenuItems] = useState([]);
    const menuRef = useRef(null); // Dùng để tham chiếu tới menu

    const role = 'guest'; // Mặc định là khách, có thể thay đổi tùy thuộc vào logic của bạn

    // Lấy menu từ MenuService
    useEffect(() => {
        const items = getMenuItems(role);
        setMenuItems(items);
    }, [role]);

    // Đóng menu khi click ra ngoài
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setMenuVisible(false); // Ẩn menu nếu click ngoài vùng menu
            }
        };

        if (menuVisible) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside); // Cleanup khi component bị unmount
        };
    }, [menuVisible]);

    const toggleMenu = () => {
        setMenuVisible(!menuVisible); // Chuyển trạng thái ẩn/hiện menu
    };

    // Đóng menu khi chọn một link
    const handleMenuItemClick = () => {
        setMenuVisible(false);
    };

    return (
        <header className="header">
            <div className="logo">Pickleball</div>
            <nav className="nav-container">
                {/* Nút để hiển thị menu */}
                <button className="menu-toggle-button" onClick={toggleMenu}>
                    ☰ Menu
                </button>
                {/* Menu chỉ hiển thị khi menuVisible = true */}
                {menuVisible && (
                    <ul className="nav-links" ref={menuRef}>
                        {menuItems.map((item, index) => (
                            <li key={index}>
                                <NavLink 
                                    to={item.path} 
                                    className={({ isActive }) => (isActive ? 'active' : '')}
                                    onClick={handleMenuItemClick} // Đóng menu khi nhấn link
                                >
                                    {item.name}
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                )}
            </nav>
        </header>
    );
}

export default Header;
