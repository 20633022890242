import React, { useState, useEffect } from 'react';
import axios from './../api/api';
import './CoachList.css';

function CoachList() {
    const [coaches, setCoaches] = useState([]); // Dữ liệu huấn luyện viên
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1); // Tổng số trang
    const [searchTerm, setSearchTerm] = useState(''); // Từ khóa tìm kiếm trong input
    const [searchQuery, setSearchQuery] = useState(''); // Từ khóa tìm kiếm thực sự khi nhấn nút
    const coachesPerPage = 10;

    // Lấy danh sách huấn luyện viên từ API với phân trang và tìm kiếm
    useEffect(() => {
        const fetchCoaches = async () => {
            try {
                const response = await axios.get('/coaches/coaches-paginated', {
                    params: {
                        page: currentPage,
                        limit: coachesPerPage,
                        search: searchQuery // Truyền từ khóa tìm kiếm khi nhấn nút
                    }
                });
                setCoaches(response.data.coaches); // Cập nhật danh sách huấn luyện viên
                setTotalPages(response.data.totalPages); // Cập nhật tổng số trang
            } catch (error) {
                console.error('Error fetching coaches:', error);
            }
        };

        fetchCoaches();
    }, [currentPage, searchQuery]); // Chỉ gọi lại khi trang hoặc từ khóa tìm kiếm thực sự thay đổi

    // Xử lý tìm kiếm huấn luyện viên khi nhấn nút
    const handleSearch = () => {
        setCurrentPage(1); // Đặt lại về trang đầu tiên khi tìm kiếm
        setSearchQuery(searchTerm); // Chỉ cập nhật searchQuery khi nhấn nút
    };

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <div className="coach-list container">
            <div className="search-bar" style={{ textAlign: 'center', marginBottom: '20px' }}>
                <input 
                    type="text" 
                    value={searchTerm} 
                    onChange={(e) => setSearchTerm(e.target.value)} 
                    placeholder="Search for coaches by name or introduction..." 
                />
                <button onClick={handleSearch}>Search</button>
            </div>

            {coaches.length > 0 ? (
                coaches.map(coach => (
                    <div key={coach._id} className="coach-item" style={{ display: 'flex', marginBottom: '20px' }}>
                        <div className="coach-avatar" style={{ flex: '0 0 150px', marginRight: '20px' }}>
                            <img 
                                src={`${process.env.REACT_APP_API_BASE_URL}/${coach.avatar}`} 
                                alt={coach.fullName} 
                                style={{ width: '100%', height: 'auto', borderRadius: '50%' }}
                            />
                        </div>
                        <div className="coach-info" style={{ flex: '1' }}>
                            <h3>{coach.fullName} ({coach.nick})</h3>
                            <p><strong>Phone:</strong> {coach.phoneNumber}</p>
                            <p><strong>Working Unit:</strong> {coach.workingUnit}</p>
                            <p><strong>Teaching Area:</strong> {coach.teachingArea}</p>
                            <p><strong>Introduction:</strong> {coach.introduction}</p>
                            <p><strong>Zalo:</strong> {coach.zaloLink}</p>
                            <p><strong>Facebook:</strong> {coach.facebookLink}</p>
                        </div>
                    </div>
                ))
            ) : (
                <p>No coaches found.</p>
            )}

            <div className="pagination" style={{ textAlign: 'center', marginTop: '20px' }}>
                <button onClick={prevPage} disabled={currentPage === 1}>Previous</button>
                <span> Page {currentPage} of {totalPages} </span>
                <button onClick={nextPage} disabled={currentPage === totalPages}>Next</button>
            </div>
        </div>
    );
}

export default CoachList;
