import React, { useState, useEffect } from 'react';
import { Form, Button, Table, Modal, Alert, Pagination } from 'react-bootstrap';
import axios from './../../../api/api';

const UserManagementForm = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [newUser, setNewUser] = useState({
    username: '',
    fullName: '',
    email: '',
    phoneNumber: '',
    roles: ['user'],
    status: 'pending',
    password: '',
  });

  useEffect(() => {
    fetchUsers(currentPage, searchQuery);
  }, [currentPage, searchQuery]);

  const fetchUsers = async (page, query) => {
    setLoading(true);
    try {
      const response = await axios.get(`/users/getUsersPaginated?page=${page}&limit=20&search=${query}`);
      setUsers(response.data.users);
      setTotalPages(response.data.totalPages);
    } catch (err) {
      setError('Lỗi khi tải danh sách người dùng.');
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset về trang 1 khi tìm kiếm mới
  };

  const handleAddUser = () => {
    setEditingUser(null);
    setNewUser({
      username: '',
      fullName: '',
      email: '',
      phoneNumber: '',
      roles: ['user'],
      status: 'pending',
      password: '',
    });
    setShowModal(true);
  };

  const handleEditUser = (user) => {
    setEditingUser(user);
    setNewUser({ ...user, password: '' }); // Không hiển thị mật khẩu
    setShowModal(true);
  };

  const handleDeleteUser = async (userId) => {
    try {
      await axios.delete(`/users/${userId}`);
      fetchUsers(currentPage, searchQuery);
    } catch (err) {
      setError('Lỗi khi xóa người dùng.');
    }
  };

  const handleSaveUser = async (e) => {
    e.preventDefault();
    try {
      if (editingUser) {
        await axios.put(`/users/${editingUser._id}`, newUser);
      } else {
        await axios.post('/users', newUser);
      }
      setShowModal(false);
      fetchUsers(currentPage, searchQuery);
    } catch (err) {
      setError('Lỗi khi lưu người dùng.');
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div>
      <h2>Quản lý người dùng</h2>

      {error && <Alert variant="danger">{error}</Alert>}

      <Form.Control
        type="text"
        placeholder="Tìm kiếm người dùng theo username hoặc email"
        value={searchQuery}
        onChange={handleSearch}
        className="mb-3"
      />

      <Button variant="primary" className="mb-3" onClick={handleAddUser}>
        Thêm người dùng mới
      </Button>

      {loading ? (
        <p>Đang tải...</p>
      ) : (
        <>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Username</th>
                <th>Họ tên</th>
                <th>Email</th>
                <th>Số điện thoại</th>
                <th>Trạng thái</th>
                <th>Vai trò</th>
                <th>Hành động</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user._id}>
                  <td>{user.username}</td>
                  <td>{user.fullName}</td>
                  <td>{user.email}</td>
                  <td>{user.phoneNumber}</td>
                  <td>{user.status}</td>
                  <td>{user.roles.join(', ')}</td>
                  <td>
                    <Button variant="warning" onClick={() => handleEditUser(user)}>
                      Sửa
                    </Button>{' '}
                    <Button variant="danger" onClick={() => handleDeleteUser(user._id)}>
                      Xóa
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <Pagination>
            {[...Array(totalPages).keys()].map((page) => (
              <Pagination.Item
                key={page + 1}
                active={page + 1 === currentPage}
                onClick={() => handlePageChange(page + 1)}
              >
                {page + 1}
              </Pagination.Item>
            ))}
          </Pagination>
        </>
      )}

      {/* Modal thêm/sửa người dùng */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{editingUser ? 'Sửa người dùng' : 'Thêm người dùng mới'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSaveUser}>
            <Form.Group controlId="username">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                value={newUser.username}
                onChange={(e) => setNewUser({ ...newUser, username: e.target.value })}
                required
              />
            </Form.Group>
            <Form.Group controlId="fullName">
              <Form.Label>Họ tên</Form.Label>
              <Form.Control
                type="text"
                value={newUser.fullName}
                onChange={(e) => setNewUser({ ...newUser, fullName: e.target.value })}
              />
            </Form.Group>
            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={newUser.email}
                onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
                required
              />
            </Form.Group>
            <Form.Group controlId="phoneNumber">
              <Form.Label>Số điện thoại</Form.Label>
              <Form.Control
                type="text"
                value={newUser.phoneNumber}
                onChange={(e) => setNewUser({ ...newUser, phoneNumber: e.target.value })}
              />
            </Form.Group>
            <Form.Group controlId="status">
              <Form.Label>Trạng thái</Form.Label>
              <Form.Control
                as="select"
                value={newUser.status}
                onChange={(e) => setNewUser({ ...newUser, status: e.target.value })}
              >
                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
                <option value="draft">Draft</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="roles">
              <Form.Label>Vai trò</Form.Label>
              <Form.Control
                as="select"
                multiple
                value={newUser.roles}
                onChange={(e) =>
                  setNewUser({
                    ...newUser,
                    roles: [].slice.call(e.target.selectedOptions).map((item) => item.value),
                  })
                }
              >
                <option value="user">User</option>
                <option value="manager">Manager</option>
                <option value="admin">Admin</option>
                <option value="cus">Customer care</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="password">
              <Form.Label>{editingUser ? 'Mật khẩu mới' : 'Mật khẩu'}</Form.Label>
              <Form.Control
                type="password"
                value={newUser.password}
                onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
                required={!editingUser} // Bắt buộc khi thêm mới, không bắt buộc khi sửa
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              {editingUser ? 'Cập nhật' : 'Thêm mới'}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UserManagementForm;
