import React, { useState, useEffect } from 'react';
import axios from './../../../api/api';
import { Button, Form, Table, Container, Modal, Pagination } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';


const ArticleListPage = () => {
    const [articles, setArticles] = useState([]);
    const [newArticle, setNewArticle] = useState({ key: '', title: '', summary: '', thumbnailImage: '', status: 'draft', releaseDate: '', contents: [] });
    const [editingArticle, setEditingArticle] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [search, setSearch] = useState('');  // Ô tìm kiếm
    const [currentPage, setCurrentPage] = useState(1);  // Trang hiện tại
    const [totalPages, setTotalPages] = useState(1);  // Tổng số trang
    const navigate = useNavigate();

    useEffect(() => {
        fetchArticles();
    }, [currentPage, search]);

    const fetchArticles = async () => {
        const response = await axios.get(`/articles?page=${currentPage}&limit=5&search=${search}`);
        setArticles(response.data.articles);
        setTotalPages(response.data.totalPages);
    };

    // Thêm hoặc cập nhật bài viết
    const handleSaveArticle = async () => {
        if (editingArticle) {
            await axios.put(`/articles/${editingArticle._id}`, editingArticle);
        } else {
            await axios.post('/articles', newArticle);
            setNewArticle({ key: '', title: '', summary: '', thumbnailImage: '', status: 'draft', releaseDate: '', contents: [] });
        }
        setShowModal(false);
        setEditingArticle(null);
        fetchArticles();
    };

    // Xóa bài viết
    const handleDeleteArticle = async (id) => {
        await axios.delete(`/articles/${id}`);
        setDeleteId(null);
        fetchArticles();
    };

    const openContentPage = (articleId) => {
        navigate(`/admin/articles/${articleId}/contents`);
    };

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('avatar', file);  // 'avatar' hoặc 'thumbnailImage' tùy theo tên trường trong API
    
        try {
            const response = await axios.post('/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            const fileUrl = response.data.url;
            if (editingArticle) {
                setEditingArticle({ ...editingArticle, thumbnailImage: fileUrl });
            } else {
                setNewArticle({ ...newArticle, thumbnailImage: fileUrl });
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    // Mở Modal cho thêm hoặc sửa
    const openModal = (article = null) => {
        setEditingArticle(article);
        setNewArticle(article || { key: '', title: '', summary: '', thumbnailImage: '', status: 'draft', releaseDate: '', contents: [] });
        setShowModal(true);
    };

    // Đóng Modal
    const closeModal = () => {
        setEditingArticle(null);
        setShowModal(false);
    };

    // Xác nhận xóa
    const confirmDelete = (id) => {
        setDeleteId(id);
    };
    
    // Xử lý tìm kiếm
    const handleSearchChange = (e) => {
        setSearch(e.target.value);
        setCurrentPage(1);  // Khi tìm kiếm, reset về trang 1
    };

    // Phân trang
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <Container>
            <h2 className="my-4">Article Management</h2>

            {/* Ô tìm kiếm */}
            <Form.Group className="mb-3">
                <Form.Control
                    type="text"
                    placeholder="Search articles by title..."
                    value={search}
                    onChange={handleSearchChange}
                />
            </Form.Group>

            <Button variant="primary" className="mb-4" onClick={() => openModal()}>Create New Article</Button>

            <h3>Existing Articles</h3>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Key</th>
                        <th>Title</th>
                        <th>Thumbnail</th>
                        <th>Status</th>
                        <th>Release Date</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {articles.map((article) => (
                        <tr key={article._id}>
                            <td>{article.key}</td>
                            <td>{article.title}</td>
                            <td>
                                <img src={`${process.env.REACT_APP_API_BASE_URL}/${article.thumbnailImage}`} alt="Avatar" width="100" />
                            </td>
                            <td>{article.status}</td>
                            <td>{article.releaseDate ? new Date(article.releaseDate).toLocaleDateString() : 'N/A'}</td>
                            <td>
                                <Button variant="warning" className="me-2" onClick={() => openModal(article)}>Edit</Button>
                                <Button variant="danger" onClick={() => confirmDelete(article._id)}>Delete</Button>
                                <Button variant="info" onClick={() => openContentPage(article._id)}>Contents</Button> {/* Nút Contents */}

                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {/* Phân trang */}
            <Pagination>
                {[...Array(totalPages).keys()].map(page => (
                    <Pagination.Item
                        key={page + 1}
                        active={page + 1 === currentPage}
                        onClick={() => handlePageChange(page + 1)}
                    >
                        {page + 1}
                    </Pagination.Item>
                ))}
            </Pagination>

            {/* Modal thêm/sửa bài viết */}
            <Modal show={showModal} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{editingArticle ? 'Edit Article' : 'Create New Article'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Key</Form.Label>
                            <Form.Control
                                type="text"
                                value={editingArticle ? editingArticle.key : newArticle.key}
                                onChange={(e) => {
                                    if (editingArticle) {
                                        setEditingArticle({ ...editingArticle, key: e.target.value });
                                    } else {
                                        setNewArticle({ ...newArticle, key: e.target.value });
                                    }
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type="text"
                                value={editingArticle ? editingArticle.title : newArticle.title}
                                onChange={(e) => {
                                    if (editingArticle) {
                                        setEditingArticle({ ...editingArticle, title: e.target.value });
                                    } else {
                                        setNewArticle({ ...newArticle, title: e.target.value });
                                    }
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Summary</Form.Label>
                            <Form.Control
                                type="text"
                                value={editingArticle ? editingArticle.summary : newArticle.summary}
                                onChange={(e) => {
                                    if (editingArticle) {
                                        setEditingArticle({ ...editingArticle, summary: e.target.value });
                                    } else {
                                        setNewArticle({ ...newArticle, summary: e.target.value });
                                    }
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Thumbnail Image</Form.Label>
                            <Form.Control type="file" onChange={handleFileChange} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>Close</Button>
                    <Button variant="primary" onClick={handleSaveArticle}>
                        {editingArticle ? 'Save Changes' : 'Create Article'}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal xác nhận xóa */}
            <Modal show={!!deleteId} onHide={() => setDeleteId(null)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this article?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setDeleteId(null)}>Cancel</Button>
                    <Button variant="danger" onClick={() => handleDeleteArticle(deleteId)}>Delete</Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default ArticleListPage;
