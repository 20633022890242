import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from './../api/api';
import './MemberList.css';
import { Container } from 'react-bootstrap';

function MemberList() {
    const { clubId } = useParams();
    const [members, setMembers] = useState([]);
    const [clubInfo, setClubInfo] = useState(null); // State để lưu thông tin CLB

    useEffect(() => {
        const fetchMembersAndClubInfo = async () => {
            try {
                const response = await axios.get(`/clubs/${clubId}/members`);
                setMembers(response.data.members);
                
                // Giả sử API trả về thông tin CLB trong dữ liệu phản hồi
                if (response.data.members.length > 0) {
                    setClubInfo(response.data.members[0].clubId); // Lấy thông tin CLB từ thành viên đầu tiên
                }
            } catch (error) {
                console.error('Error fetching members:', error);
            }
        };

        fetchMembersAndClubInfo();
    }, [clubId]);

    return (
        <Container>
            {/* Hiển thị thông tin CLB ở đầu trang */}
            {clubInfo && (
                <div className="club-info">
                    <h1>{clubInfo.name}</h1>
                    <p><strong>Địa chỉ:</strong> {clubInfo.location}</p>
                    <p><strong>Chủ tịch:</strong> {clubInfo.president}</p>
                    <p><strong>Mô tả:</strong> {clubInfo.notes}</p>
                </div>
            )}

            <div className="member-list">
                {members.length > 0 ? (
                    members.map(member => (
                        <div className="myclub-card" key={member._id}>
                            <h2 className="myclub-name">CÂU LẠC BỘ {member.clubId.name}</h2>
                            <h2 className="myclub-card-title">THẺ HỘI VIÊN</h2>
                            <h2 className="myclub-card-title">{member.userId.fullName}</h2>

                            <img
                                className="myclub-avatar"
                                src={`${process.env.REACT_APP_API_BASE_URL}/${member.userId.avatar}`}
                                alt="Avatar"
                                width="100"
                            />

                            <p><span className="myclub-label">Địa chỉ:</span> <span className="myclub-info">{member.clubId.location}</span></p>
                            <p><span className="myclub-label">Ghi chú:</span> <span className="myclub-info">{member.clubId.notes}</span></p>

                            <p><span className="myclub-label">Email hội viên:</span> <span className="myclub-info">{member.userId.email}</span></p>
                            <p><span className="myclub-label">Số điện thoại hội viên:</span> <span className="myclub-info">{member.userId.phoneNumber}</span></p>
                            <p><span className="myclub-label">Tình trạng:</span> <span className="myclub-info">{member.status}</span></p>
                        </div>
                    ))
                ) : (
                    <p>No members found.</p>
                )}
            </div>
        </Container>
    );
}

export default MemberList;
