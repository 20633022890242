import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../api/api';
import { Carousel } from 'react-bootstrap';
import './BookStadium.css';

function BookStadium() {
    const { stadiumId } = useParams();
    const [days, setDays] = useState(1);
    const [timeSlots, setTimeSlots] = useState([]);
    const [stadiumInfo, setStadiumInfo] = useState(null);
    const currentDate = new Date().toLocaleDateString();

    useEffect(() => {
        const fetchTimeSlots = async () => {
            try {
                const response = await axios.get(`/stadiums/${stadiumId}/timeslots`, {
                    params: { days }
                });
                setTimeSlots(response.data.timeSlots);
            } catch (error) {
                console.error('Error fetching time slots:', error);
            }
        };

        const fetchStadiumInfo = async () => {
            try {
                const response = await axios.get(`/stadiums/${stadiumId}`);
                setStadiumInfo(response.data);
            } catch (error) {
                console.error('Error fetching stadium info:', error);
            }
        };

        fetchTimeSlots();
        fetchStadiumInfo();
    }, [stadiumId, days]);

    const handleDaysChange = (e) => {
        setDays(e.target.value);
    };

    const handleBookingSlot = () => {
        window.alert('Chức năng đang xây dựng, vui lòng liên hệ theo thông tin chủ sân.');
    };

    const groupSlotsByDate = (slots) => {
        return slots.reduce((grouped, slot) => {
            const date = new Date(slot.date).toLocaleDateString();
            if (!grouped[date]) {
                grouped[date] = [];
            }
            grouped[date].push(slot);
            return grouped;
        }, {});
    };

    const groupedSlots = groupSlotsByDate(timeSlots);

    return (
        <div className="container">
            {/* Phần thông tin cơ bản của sân */}
            {stadiumInfo ? (
                <div className="stadium-info-row">
                    <div className="stadium-info-column">
                        <img
                            src={`${process.env.REACT_APP_API_BASE_URL}/${stadiumInfo.avatar}`}
                            alt={stadiumInfo.stadiumName}
                            className="stadium-avatar"
                        />
                    </div>
                    <div className="stadium-info-column">
                        <h2>{stadiumInfo.stadiumName}</h2>
                        <p><strong>Địa chỉ:</strong> {stadiumInfo.address}</p>
                        <p><strong>Điện thoại:</strong> {stadiumInfo.contactPhone}</p>
                        <p><strong>Người đại diện:</strong> {stadiumInfo.representativeName}</p>

                        {/* Hiển thị lời giới thiệu nếu có */}
                        {stadiumInfo.introduction && (
                            <p><strong>Giới thiệu:</strong> {stadiumInfo.introduction}</p>
                        )}
                    </div>
                </div>
            ) : (
                <p>Đang tải thông tin sân...</p>
            )}

            <p>Ngày hiện tại: {currentDate}</p>

            <div className="form-group">
                <label>Số ngày muốn xem (1-7):</label>
                <select value={days} onChange={handleDaysChange}>
                    {[...Array(7).keys()].map(day => (
                        <option key={day + 1} value={day + 1}>{day + 1}</option>
                    ))}
                </select>
                <br /><br />
            </div>

            {/* Nếu không có slots */}
            {Object.keys(groupedSlots).length === 0 ? (
                <p className="no-slots-message">Không có kết quả phù hợp cho các ngày đã chọn.</p>
            ) : (
                <div className="time-slot-list">
                    {Object.keys(groupedSlots).map((date, index) => (
                        <div key={index} style={{ marginBottom: '20px' }}>
                            <h3>Ngày: {date}</h3>
                            <div className="slot-row">
                                {groupedSlots[date].map((slot, idx) => (
                                    <div key={idx} className="time-slot-item">
                                        <p>
                                            <strong>Sân:</strong> {slot.playgroundId.name}<br />
                                            <strong>Giờ:</strong> {slot.fromHour}:{slot.fromMinute} - {slot.toHour}:{slot.toMinute}<br />
                                            <span className="status-text">
                                                <strong>Tình trạng:</strong> {slot.status === 'rỗi' ? 'Còn rỗi ' : 'Bận '}
                                            </span>
                                            {slot.status === 'rỗi' && (
                                                <button onClick={handleBookingSlot}>Đặt sân</button>
                                            )}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {/* Phần thông tin mở rộng thêm (Slide ảnh, YouTube, bảng giá, Google Map) */}
            {stadiumInfo && (
                <>
                    {/* Hiển thị Slide ảnh nếu có imageLinks */}
                    {stadiumInfo.imageLinks && stadiumInfo.imageLinks.length > 0 && (
                        <Carousel>
                            {stadiumInfo.imageLinks.map((image, index) => (
                                <Carousel.Item key={index}>
                                    <img
                                        className="d-block w-100"
                                        src={`${process.env.REACT_APP_API_BASE_URL}/${image}`}
                                        alt={`Image ${index}`}
                                    />
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    )}

                    {/* Hiển thị video YouTube nếu có youtubeLink */}
                    {stadiumInfo.youtubeLink && (
                        <div className="video-container">
                            <iframe
                                width="100%"
                                height="315"
                                src={`https://www.youtube.com/embed/${stadiumInfo.youtubeLink.split('v=')[1]}`}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                title="YouTube Video"
                            ></iframe>
                        </div>
                    )}

                    {/* Hiển thị bảng giá nếu có */}
                    {stadiumInfo.pricingTable && (
                        <div className="pricing-table">
                            <h3>Bảng giá</h3>
                            <div dangerouslySetInnerHTML={{ __html: stadiumInfo.pricingTable }} />
                        </div>
                    )}

                    {/* Hiển thị Google Map nếu có googleMapLink */}
                    {/* Hiển thị Google Map nếu có googleMapLink */}
                    {stadiumInfo.googleMapLink && (
                        <div className="map-container">
                            <iframe
                                src={stadiumInfo.googleMapLink}
                                width="100%"
                                height="450"
                                style={{ border: 0 }}
                                allowFullScreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                                title="Google Maps"
                            ></iframe>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}

export default BookStadium;
