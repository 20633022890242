import React, { useState, useEffect } from 'react';
import { Form, Button, Table, Alert, Modal, Pagination } from 'react-bootstrap';
import api from '../../../api/api';  // API helper

const ClubPracticeSessionManagement = () => {
    const [clubs, setClubs] = useState([]);
    const [selectedClub, setSelectedClub] = useState('');
    const [practiceSessions, setPracticeSessions] = useState([]);
    const [matches, setMatches] = useState([]); // Danh sách các trận đấu
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const [showMatchModal, setShowMatchModal] = useState(false); // Modal cho trận đấu
    const [showAddMatchForm, setShowAddMatchForm] = useState(false); // Modal thêm mới trận đấu
    const [showModal, setShowModal] = useState(false);  // Modal để thêm/sửa buổi tập luyện
    const [editingSession, setEditingSession] = useState(null); // Buổi tập luyện đang được chỉnh sửa
    const [editingMatch, setEditingMatch] = useState(null); // Để lưu trữ trận đấu cần sửa
    const [sessionId, setSessionId] = useState(''); // Practice Session ID
    const [showDeleteModal, setShowDeleteModal] = useState(false); // Modal for delete confirmation
    const [matchToDelete, setMatchToDelete] = useState(null); // Store match to delete

    const [newMatch, setNewMatch] = useState({
        team1Nick1: '',
        team1Nick2: '',
        team2Nick1: '',
        team2Nick2: '',
        team1Score: '',
        team2Score: '',
    });

    const [newSession, setNewSession] = useState({
        name: '',
        practiceDate: '',
        notes: '',
    });

    useEffect(() => {
        const fetchClubs = async () => {
            try {
                const response = await api.get('/clubs/club-of-users');
                const userClubs = response.data.clubs;

                if (userClubs.length === 0) {
                    setError("Bạn không được quản trị CLB nào, vui lòng liên hệ Chủ nhiệm để được cấp quyền.");
                } else {
                    setClubs(userClubs);
                    setSelectedClub(userClubs[0].clubId._id);
                    fetchPracticeSessions(userClubs[0].clubId._id, 1);
                }
            } catch (err) {
                console.error("Error fetching clubs:", err);
                setError("Lỗi khi tải danh sách câu lạc bộ.");
            } finally {
                setLoading(false);
            }
        };

        fetchClubs();
    }, []);

    const fetchPracticeSessions = async (clubId, page) => {
        try {
            const response = await api.get(`/practice-sessions/${clubId}/practices?page=${page}&limit=20`);
            setPracticeSessions(response.data.sessions);
        } catch (err) {
            console.error("Error fetching practice sessions:", err);
            setError("Lỗi khi tải dữ liệu buổi tập luyện.");
        }
    };

    const fetchMatches = async (practiceSessionId) => {
        try {
            const response = await api.get(`/matches/${practiceSessionId}/matches-of-practice`);
            setMatches(response.data.matches);
        } catch (err) {
            console.error("Error fetching matches:", err);
            setError("Lỗi khi tải danh sách trận đấu.");
        }
    };

    const handleShowMatches = (sessionId) => {
        setSessionId(sessionId); // Lưu lại session ID
        fetchMatches(sessionId); // Lấy danh sách trận đấu
        setShowMatchModal(true); // Mở modal
    };

    const handleAddMatch = async () => {
        const payload = {
            practiceSessionId: sessionId,
            team1: [newMatch.team1Nick1, newMatch.team1Nick2],
            team2: [newMatch.team2Nick1, newMatch.team2Nick2],
            team1Score: newMatch.team1Score,
            team2Score: newMatch.team2Score,
        };

        try {
            await api.post('/matches/add_match_to_practice', payload);
            setNewMatch({
                team1Nick1: '',
                team1Nick2: '',
                team2Nick1: '',
                team2Nick2: '',
                team1Score: '',
                team2Score: '',
            });
            fetchMatches(sessionId); // Refresh danh sách trận đấu
        } catch (err) {
            console.error("Error adding match:", err);
            setError("Lỗi khi thêm trận đấu.");
        }
    };
    const handleDeleteMatch = async () => {
        try {
            await api.delete(`/matches/${matchToDelete.matchId}`);
            setShowDeleteModal(false);
            fetchMatches(sessionId); // Refresh the list of matches
        } catch (err) {
            console.error("Error deleting match:", err);
            setError("Lỗi khi xóa trận đấu.");
        }
    };

    const openDeleteModal = (match) => {
        setMatchToDelete(match);
        setShowDeleteModal(true);
    };

    /*const handleEditMatch = async () => {
        const payload = {
            team1: [newMatch.team1Nick1, newMatch.team1Nick2],
            team2: [newMatch.team2Nick1, newMatch.team2Nick2],
            team1Score: newMatch.team1Score,
            team2Score: newMatch.team2Score,
        };

        try {
            await api.put(`/matches/edit-match-of-practice/${editingMatch._id}`, payload);
            setEditingMatch(null); // Reset trạng thái sửa
            fetchMatches(sessionId); // Refresh danh sách trận đấu
        } catch (err) {
            console.error("Error editing match:", err);
            setError("Lỗi khi chỉnh sửa trận đấu.");
        }
    };*/
    const handleEditMatch = async () => {
        if (!editingMatch || !editingMatch._id) {
            console.error("Error: editingMatch._id is undefined");
            setError("Không thể xác định trận đấu để chỉnh sửa.");
            return;
        }

        const payload = {
            team1: [newMatch.team1Nick1, newMatch.team1Nick2],
            team2: [newMatch.team2Nick1, newMatch.team2Nick2],
            team1Score: newMatch.team1Score,
            team2Score: newMatch.team2Score,
        };

        try {
            await api.put(`/matches/edit-match-of-practice/${editingMatch._id}`, payload);
            setEditingMatch(null); // Reset trạng thái sửa
            fetchMatches(sessionId); // Refresh danh sách trận đấu
        } catch (err) {
            console.error("Error editing match:", err);
            setError("Lỗi khi chỉnh sửa trận đấu.");
        }
    };

    /*const handleOpenEditMatch = (match) => {
        setEditingMatch(match);
        setNewMatch({
            team1Nick1: match.team1[0]?.nick || '',  // Lấy nick từ phần tử đầu tiên của team1
            team1Nick2: match.team1[1]?.nick || '',  // Lấy nick từ phần tử thứ hai của team1, nếu có
            team2Nick1: match.team2[0]?.nick || '',  // Lấy nick từ phần tử đầu tiên của team2
            team2Nick2: match.team2[1]?.nick || '',  // Lấy nick từ phần tử thứ hai của team2, nếu có
            team1Score: match.finalScore.team1Total,
            team2Score: match.finalScore.team2Total,
        });
        setShowAddMatchForm(true);
    };*/
    const handleOpenEditMatch = (match) => {
        console.log("Selected match for editing:", match);
        setEditingMatch({ ...match, _id: match.matchId });  // Gán matchId vào _id để dễ sử dụng
        setNewMatch({
            team1Nick1: match.team1[0]?.nickInClub || '',
            team1Nick2: match.team1[1]?.nickInClub || '',
            team2Nick1: match.team2[0]?.nickInClub || '',
            team2Nick2: match.team2[1]?.nickInClub || '',
            team1Score: match.finalScore.team1Total,
            team2Score: match.finalScore.team2Total,
        });
        setShowAddMatchForm(true);  // Mở form thêm/sửa trận đấu
    };

    const handleAddSession = () => {
        setEditingSession(null);
        setNewSession({ name: '', practiceDate: '', notes: '' });
        setShowModal(true);
    };

    const handleEditSession = (session) => {
        setEditingSession(session);
        setNewSession({
            name: session.name,
            practiceDate: session.practiceDate,
            notes: session.notes,
        });
        setShowModal(true);
    };

    const handleDeleteSession = async (sessionId) => {
        try {
            await api.delete(`/practice-sessions/${sessionId}`);
            fetchPracticeSessions(selectedClub, 1);  // Reload the practice sessions
        } catch (err) {
            console.error("Error deleting session:", err);
            setError("Lỗi khi xóa buổi tập luyện.");
        }
    };

    const handleSaveSession = async (event) => {
        event.preventDefault();
        const formData = {
            name: newSession.name,
            practiceDate: newSession.practiceDate,
            clubId: selectedClub,
            notes: newSession.notes,
        };

        try {
            if (editingSession) {
                await api.put(`/practice-sessions/${editingSession._id}`, formData);
            } else {
                await api.post('/practice-sessions', formData);
            }
            setShowModal(false);
            fetchPracticeSessions(selectedClub, 1);  // Reload practice sessions
        } catch (err) {
            console.error("Error saving session:", err);
            setError("Lỗi khi lưu buổi tập luyện.");
        }
    };

    return (
        <div>
            <h2>Quản lý buổi tập luyện</h2>

            {error && <Alert variant="danger">{error}</Alert>}

            <Button variant="primary" className="my-3" onClick={handleAddSession}>
                Thêm buổi tập luyện mới
            </Button>

            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Tên buổi tập luyện</th>
                        <th>Ngày tập luyện</th>
                        <th>Ghi chú</th>
                        <th>Hành động</th>
                    </tr>
                </thead>
                <tbody>
                    {practiceSessions.map(session => (
                        <tr key={session._id}>
                            <td>{session.name}</td>
                            <td>{new Date(session.practiceDate).toLocaleDateString()}</td>
                            <td>{session.notes}</td>
                            <td>
                                <Button variant="primary" onClick={() => handleShowMatches(session._id)}>
                                    Nhập kết quả trận đấu
                                </Button>{' '}
                                <Button variant="warning" onClick={() => handleEditSession(session)}>
                                    Sửa
                                </Button>{' '}
                                <Button variant="danger" onClick={() => handleDeleteSession(session._id)}>
                                    Xóa
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {/* Modal thêm/sửa buổi tập luyện */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{editingSession ? 'Sửa buổi tập luyện' : 'Thêm buổi tập luyện mới'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSaveSession}>
                        <Form.Group controlId="name">
                            <Form.Label>Tên buổi tập luyện</Form.Label>
                            <Form.Control
                                type="text"
                                value={newSession.name}
                                onChange={(e) => setNewSession({ ...newSession, name: e.target.value })}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="practiceDate">
                            <Form.Label>Ngày tập luyện</Form.Label>
                            <Form.Control
                                type="date"
                                value={newSession.practiceDate ? new Date(newSession.practiceDate).toISOString().split('T')[0] : ''}  // Kiểm tra nếu ngày hợp lệ
                                onChange={(e) => setNewSession({ ...newSession, practiceDate: e.target.value })}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="notes">
                            <Form.Label>Ghi chú</Form.Label>
                            <Form.Control
                                as="textarea"
                                value={newSession.notes}
                                onChange={(e) => setNewSession({ ...newSession, notes: e.target.value })}
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            {editingSession ? 'Cập nhật' : 'Thêm mới'}
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* Modal hiển thị danh sách trận đấu */}
            <Modal show={showMatchModal} onHide={() => setShowMatchModal(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Danh sách trận đấu</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Button variant="success" onClick={() => setShowAddMatchForm(true)}>Thêm mới trận đấu</Button>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Team 1</th>
                                <th>Team 2</th>
                                <th>Điểm Team 1</th>
                                <th>Điểm Team 2</th>
                                <th>Hành động</th>
                            </tr>
                        </thead>
                        <tbody>
                            {matches.map(match => (
                                <tr key={match._id}>
                                    <td>{match.team1.map(player => player.nickInClub).join(', ')}</td>
                                    <td>{match.team2.map(player => player.nickInClub).join(', ')}</td>
                                    <td>{match.finalScore.team1Total}</td>
                                    <td>{match.finalScore.team2Total}</td>
                                    <td>
                                        <Button variant="warning" onClick={() => handleOpenEditMatch(match)}>Sửa</Button>
                                        <Button variant="danger" onClick={() => openDeleteModal(match)}>Xóa</Button>

                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>

                    {/* Form thêm/sửa trận đấu */}
                    {showAddMatchForm && (
                        <Form>
                            <Form.Group>
                                <Form.Label>Nick 1 Team 1</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={newMatch.team1Nick1}
                                    onChange={(e) => setNewMatch({ ...newMatch, team1Nick1: e.target.value })}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Nick 2 Team 1</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={newMatch.team1Nick2}
                                    onChange={(e) => setNewMatch({ ...newMatch, team1Nick2: e.target.value })}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Nick 1 Team 2</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={newMatch.team2Nick1}
                                    onChange={(e) => setNewMatch({ ...newMatch, team2Nick1: e.target.value })}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Nick 2 Team 2</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={newMatch.team2Nick2}
                                    onChange={(e) => setNewMatch({ ...newMatch, team2Nick2: e.target.value })}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Điểm Team 1</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={newMatch.team1Score}
                                    onChange={(e) => setNewMatch({ ...newMatch, team1Score: e.target.value })}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Điểm Team 2</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={newMatch.team2Score}
                                    onChange={(e) => setNewMatch({ ...newMatch, team2Score: e.target.value })}
                                />
                            </Form.Group>
                            <Button variant="primary" onClick={editingMatch ? handleEditMatch : handleAddMatch}>
                                {editingMatch ? 'Cập nhật' : 'Ghi lại'}
                            </Button>
                        </Form>
                    )}
                </Modal.Body>
            </Modal>
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Xác nhận xóa trận đấu</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Bạn có chắc chắn muốn xóa trận đấu này không? Hành động này không thể hoàn tác.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Hủy
                    </Button>
                    <Button variant="danger" onClick={handleDeleteMatch}>
                        Xóa
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    );
};

export default ClubPracticeSessionManagement;
