import React, { useEffect, useState } from 'react';
import axios from './../api/api';
import Slider from 'react-slick'; // Import Slider từ react-slick

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const RefereesHomepage = ({ refereeConfigValue }) => {
    const [referees, setReferees] = useState([]);

    useEffect(() => {

        const fetchReferees = async () => {
            try {
                const refereeIdsArray = refereeConfigValue.slice(1, -1).split(',');
                const sanitizedIds = refereeIdsArray.map(id => id.trim());

                const response = await axios.get('/referees/referees-by-ids', {
                    params: { ids: sanitizedIds }
                });

                setReferees(response.data);
            } catch (error) {
                console.error('Error fetching referees:', error);
            }
        };

        if (refereeConfigValue) {
            fetchReferees();
        }
    }, [refereeConfigValue]);

    // Cấu hình cho slider
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2, // Hiển thị 2 trọng tài trên mỗi dòng
        slidesToScroll: 1, // Trượt một lần 1 nhóm
        autoplay: true,
        autoplaySpeed: 6000, // Tự động chuyển sau 6 giây
    };

    return (
        <div style={{ backgroundColor: '#E6F7FF', padding: '20px' }}> {/* Màu nền xanh nhạt */}
            <div style={{
                backgroundColor: '#007BFF', // Màu nền xanh đậm cho phần tiêu đề
                color: 'white',              // Chữ trắng
                textAlign: 'center',         // Canh giữa
                padding: '10px',             // Khoảng cách trên dưới
                fontSize: '1.5em',           // Kích thước chữ nhỏ hơn
                marginBottom: '20px'         // Khoảng cách dưới phần tiêu đề
            }}>
                Trọng tài tiêu biểu
            </div>
            {referees.length > 0 && (
                <Slider {...settings}>
                    {referees.map((referee, index) => (
                        <div
                            key={index}
                            style={{
                                padding: '20px',                // Khoảng cách bên trong khung
                                display: 'flex',
                                flexDirection: 'column',        // Căn dọc theo cột (ảnh trên, nội dung dưới)
                                alignItems: 'center',           // Căn giữa tất cả nội dung
                                border: '2px solid #007BFF',    // Thêm viền màu xanh
                                borderRadius: '10px',           // Bo góc nhẹ cho khung
                                width: '250px',                 // Chiều rộng cố định
                                backgroundColor: '#fff',        // Nền trắng cho khung
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Bóng đổ giúp khung nổi bật
                                margin: '20px auto',            // Căn giữa khung và khoảng cách phía dưới
                                position: 'relative',           // Đảm bảo kẻ dọc nằm chính xác
                                borderRight: index < referees.length - 1 ? '3px solid white' : 'none', // Đường kẻ dọc màu trắng trừ phần tử cuối cùng
                            }}
                        >
                            {/* Cột hình ảnh căn giữa */}
                            <div style={{
                                marginBottom: '15px',
                                display: 'flex',               // Sử dụng flex để căn giữa
                                justifyContent: 'center',      // Căn giữa theo chiều ngang
                                alignItems: 'center',          // Căn giữa theo chiều dọc
                                width: '100%'                  // Chiều rộng cột chứa ảnh
                            }}>
                                <img
                                    src={`${process.env.REACT_APP_API_BASE_URL}/${referee.avatar}`}
                                    alt={referee.nick}
                                    style={{
                                        width: '150px',               // Kích thước ảnh
                                        height: '150px',              // Kích thước ảnh
                                        borderRadius: '50%',          // Làm tròn ảnh
                                        objectFit: 'cover',           // Đảm bảo ảnh không bị biến dạng
                                        objectPosition: 'center'      // Căn giữa ảnh
                                    }}
                                />
                            </div>
                            {/* Cột nội dung */}
                            <div style={{ textAlign: 'center' }}>
                                <h3>{referee.fullName}</h3>
                                <p><strong>Phone:</strong> {referee.phoneNumber}</p>
                                <p>{referee.description}</p>
                            </div>
                        </div>
                    ))}
                </Slider>
            )}
        </div>

    );
};

export default RefereesHomepage;
