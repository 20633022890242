import React, { useState, useEffect } from 'react';
//import Banner from './Banner';
import Intro from './Intro';
//import Sports from './Sports';
//import Features from './Features';
import VideoListCarousel from './VideoListCarousel'; // Assuming you already have this component
import ClubHomepage from './ClubHomepage';
import CoachesHomepage from './CoachesHomepage';
import RefereesHomepage from './RefereesHomepage';
import StadiumHomepage from './StadiumHomepage';


import axios from '../api/api'; // Make sure the path to your axios instance is correct

function Home() {
    const [videoConfigValue, setVideoConfigValue] = useState('');
    const [clubConfigValue, setClubConfigValue] = useState('');
    const [coachConfigValue, setCoachConfigValue] = useState('');
    const [refereeConfigValue, setRefereeConfigValue] = useState('');
    const [stadiumConfigValue, setStadiumConfigValue] = useState('');

    useEffect(() => {
        const fetchClubConfig = async () => {
            try {                
                const responseClub = await axios.get('/configurations/clubs-homepage');
                console.log('Club Config Value:', responseClub.data.configValue); // Log giá trị để kiểm tra
                setClubConfigValue(responseClub.data.configValue);
    
            } catch (error) {
                console.error('Error fetching club config:', error);
            }
        };
    
        const fetchVieoConfig = async () => {
            try {
                const response = await axios.get('/configurations/videos-homepage');
                console.log('Video Config Value:', response.data.configValue); // Log giá trị để kiểm tra
                setVideoConfigValue(response.data.configValue);
            } catch (error) {
                console.error('Error fetching videos config:', error);
            }
        };        
        const fetchCoachConfig = async () => {
            try {
                const response = await axios.get('/configurations/coaches-homepage');
                setCoachConfigValue(response.data.configValue);
            } catch (error) {
                console.error('Error fetching coaches config:', error);
            }
        };
        const fetchRefereeConfig = async () => {
            try {
                const response = await axios.get('/configurations/referees-homepage');
                setRefereeConfigValue(response.data.configValue);
            } catch (error) {
                console.error('Error fetching referees config:', error);
            }
        };
        const fetchStadiumConfig = async () => {
            try {
                const response = await axios.get('/configurations/stadiums-homepage');
                setStadiumConfigValue(response.data.configValue);
            } catch (error) {
                console.error('Error fetching stadiums config:', error);
            }
        };

        fetchStadiumConfig();
        fetchRefereeConfig();
        fetchCoachConfig();

        fetchClubConfig();
        fetchVieoConfig();
        
    }, []);

    return (
        <div className="container">
            <Intro />
            {videoConfigValue && <VideoListCarousel videoConfigValue={videoConfigValue} />}
            
            {clubConfigValue && <ClubHomepage clubConfigValue={clubConfigValue} />}

            {coachConfigValue && <CoachesHomepage coachConfigValue={coachConfigValue} />}
            {refereeConfigValue && <RefereesHomepage refereeConfigValue={refereeConfigValue} />}

            {stadiumConfigValue && <StadiumHomepage stadiumConfigValue={stadiumConfigValue} />}

            
        </div>
    );
}

export default Home;
