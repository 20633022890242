import React from 'react';
import { useParams } from 'react-router-dom';

const NotificationPage = () => {
    const { code } = useParams(); // Lấy code từ URL

    // Tạo thông báo dựa trên giá trị của code
    const getMessage = (code) => {
        switch (code) {
            case 'activation-success':
                return 'Tài khoản của bạn đã được kích hoạt thành công!';
            case 'activation-failed':
                return 'Mã kích hoạt không hợp lệ hoặc đã hết hạn.';
            case 'already-activated':
                return 'Tài khoản của bạn đã được kích hoạt trước đó.';
            case 'activation-error':
                return 'Có lỗi xảy ra trong quá trình kích hoạt. Vui lòng thử lại sau.';
            default:
                return 'Thông báo không xác định.';
        }
    };

    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <h1>Thông báo</h1>
            <p>{getMessage(code)}</p> {/* Gọi hàm trong JSX */}
        </div>
    );
};

export default NotificationPage;
