import React, { useState, useEffect } from 'react';
import axios from './../api/api';
import './RefereeList.css';

function RefereeList() {
    const [referees, setReferees] = useState([]); // Dữ liệu trọng tài
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1); // Tổng số trang
    const [searchTerm, setSearchTerm] = useState(''); // Từ khóa tìm kiếm
    const refereesPerPage = 10;

    // Lấy danh sách trọng tài từ API với phân trang
    useEffect(() => {
        const fetchReferees = async () => {
            try {
                const response = await axios.get('/referees/referees-paginated', {
                    params: {
                        page: currentPage,
                        limit: refereesPerPage,
                        search: searchTerm // Truyền từ khóa tìm kiếm lên API
                    }
                });
                setReferees(response.data.referees); // Cập nhật danh sách trọng tài
                setTotalPages(response.data.totalPages); // Cập nhật tổng số trang
            } catch (error) {
                console.error('Error fetching referees:', error);
            }
        };

        fetchReferees();
    }, [currentPage, searchTerm]); // Gọi lại khi trang hoặc từ khóa tìm kiếm thay đổi

    // Xử lý tìm kiếm trọng tài
    const handleSearch = () => {
        setCurrentPage(1); // Đặt lại về trang đầu tiên khi tìm kiếm
    };

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <div className="referee-list container">
            <div className="search-bar" style={{ textAlign: 'center', marginBottom: '20px' }}>
                <input 
                    type="text" 
                    value={searchTerm} 
                    onChange={(e) => setSearchTerm(e.target.value)} 
                    placeholder="Search for referees by name or description..." 
                />
                <button onClick={handleSearch}>Search</button>
            </div>

            {referees.length > 0 ? (
                referees.map(referee => (
                    <div key={referee._id} className="referee-item" style={{ display: 'flex', marginBottom: '20px' }}>
                        <div className="referee-avatar" style={{ flex: '0 0 150px', marginRight: '20px' }}>
                            <img 
                                src={`${process.env.REACT_APP_API_BASE_URL}/${referee.avatar}`} 
                                alt={referee.fullName} 
                                style={{ width: '100%', height: 'auto', borderRadius: '50%' }}
                            />
                        </div>
                        <div className="referee-info" style={{ flex: '1' }}>
                            <h3>{referee.fullName} ({referee.nick})</h3>
                            <p><strong>Phone:</strong> {referee.phoneNumber}</p>
                            <p><strong>Email:</strong> {referee.email}</p>
                            <p><strong>Description:</strong> {referee.description}</p>
                            <p><strong>Zalo:</strong> {referee.zaloLink}</p>
                            <p><strong>Facebook:</strong> {referee.facebookLink}</p>
                        </div>
                    </div>
                ))
            ) : (
                <p>No referees found.</p>
            )}

            <div className="pagination" style={{ textAlign: 'center', marginTop: '20px' }}>
                <button onClick={prevPage} disabled={currentPage === 1}>Previous</button>
                <span> Page {currentPage} of {totalPages} </span>
                <button onClick={nextPage} disabled={currentPage === totalPages}>Next</button>
            </div>
        </div>
    );
}

export default RefereeList;
