import React, { useState, useEffect } from 'react';
import axios from './../../../api/api';
import { Button, Form, Table, Container, Modal, Image } from 'react-bootstrap';

const PlaygroundManagementListPage = () => {
    const [stadiums, setStadiums] = useState([]);
    const [selectedStadium, setSelectedStadium] = useState(null);
    const [playgrounds, setPlaygrounds] = useState([]);
    const [newPlayground, setNewPlayground] = useState({
        name: '',
        description: '',
        orientation: '',
        status: 'Sẵn sàng cho thuê'
    });
    const [editingPlayground, setEditingPlayground] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const [stadiumInfo, setStadiumInfo] = useState(null); // Thông tin BQL sân
    const [showStadiumModal, setShowStadiumModal] = useState(false);
    const [newImage, setNewImage] = useState(null); // Hình đại diện mới
    const [newGalleryImage, setNewGalleryImage] = useState(null); // Hình ảnh mới cho gallery

    useEffect(() => {
        fetchUserStadiums();
    }, []);

    const fetchUserStadiums = async () => {
        try {
            const response = await axios.get('/stadiums/user-stadiums');
            setStadiums(response.data);

            if (response.data.length) {
                setSelectedStadium(response.data[0]._id);
                fetchPlaygrounds(response.data[0]._id);
                fetchStadiumInfo(response.data[0]._id); // Fetch thêm thông tin BQL sân
            }
        } catch (error) {
            console.error('Error fetching stadiums:', error);
        }
    };

    const fetchPlaygrounds = async (stadiumId) => {
        try {
            const response = await axios.get(`/playgrounds/get-playgrounds/${stadiumId}`);
            setPlaygrounds(response.data);
        } catch (error) {
            console.error('Error fetching playgrounds:', error);
        }
    };

    const fetchStadiumInfo = async (stadiumId) => {
        try {
            const response = await axios.get(`/stadiums/${stadiumId}`);
            setStadiumInfo(response.data);
        } catch (error) {
            console.error('Error fetching stadium info:', error);
        }
    };

    const handleSaveStadiumInfo = async () => {
        try {
            await axios.put(`/stadiums/${selectedStadium}`, stadiumInfo);  // Gửi yêu cầu lưu dữ liệu
            setShowStadiumModal(false);  // Đóng modal sau khi lưu thành công
            fetchStadiumInfo(selectedStadium);  // Refresh thông tin BQL sân
        } catch (error) {
            console.error('Error saving stadium info:', error);
        }
    };

    const handleSavePlayground = async () => {
        try {
            if (editingPlayground && editingPlayground._id) {
                await axios.put(`/playgrounds/${editingPlayground._id}`, editingPlayground);
            } else {
                await axios.post('/playgrounds', { ...newPlayground, stadiumManagementId: selectedStadium });
            }
            setShowModal(false);
            fetchPlaygrounds(selectedStadium);
        } catch (error) {
            console.error('Error saving playground:', error);
        }
    };

    const handleDeletePlayground = async (id) => {
        try {
            await axios.delete(`/playgrounds/${id}`);
            fetchPlaygrounds(selectedStadium);
        } catch (error) {
            console.error('Error deleting playground:', error);
        }
    };

    const handleAvatarUpload = async () => {
        if (!newImage) return;
        const formData = new FormData();
        formData.append('avatar', newImage);

        try {
            await axios.put(`/stadiums/${selectedStadium}/upload-avatar`, formData);
            fetchStadiumInfo(selectedStadium); // Refresh thông tin BQL sân sau khi upload
        } catch (error) {
            console.error('Error uploading avatar:', error);
        }
    };

    const handleGalleryImageUpload = async () => {
        if (!newGalleryImage) return;
        const formData = new FormData();
        formData.append('image', newGalleryImage);

        try {
            await axios.put(`/stadiums/${selectedStadium}/add-image`, formData);
            fetchStadiumInfo(selectedStadium); // Refresh hình ảnh sau khi thêm ảnh
        } catch (error) {
            console.error('Error adding image:', error);
        }
    };

    const handleDeleteImage = async (image) => {
        try {
            await axios.delete(`/stadiums/${selectedStadium}/delete-image`, { data: { image } });
            fetchStadiumInfo(selectedStadium);
        } catch (error) {
            console.error('Error deleting image:', error);
        }
    };

    const openModal = (playground = null) => {
        setEditingPlayground(playground || null);
        setNewPlayground({
            name: '',
            description: '',
            orientation: '',
            status: 'Sẵn sàng cho thuê'
        });
        setShowModal(true);
    };

    return (
        <div>
            <h2 className="my-4">Quản lý Sân Chơi</h2>

            {stadiums.length ? (
                <Form.Group controlId="stadiumSelect">
                    <Form.Label>Chọn Ban Quản Lý Sân</Form.Label>
                    <Form.Control
                        as="select"
                        value={selectedStadium}
                        onChange={(e) => {
                            setSelectedStadium(e.target.value);
                            fetchPlaygrounds(e.target.value);
                            fetchStadiumInfo(e.target.value);
                        }}
                    >
                        {stadiums.map((stadium) => (
                            <option key={stadium._id} value={stadium._id}>
                                {stadium.stadiumName}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>
            ) : (
                <p>Bạn chưa được phân công quản lý sân nào</p>
            )}
            {/* Thêm mới sân chơi */}
            <Button variant="primary" className="my-4" onClick={() => openModal()}>Thêm mới sân chơi</Button>
            <Table striped bordered hover className="mt-4">
                <thead>
                    <tr>
                        <th>Tên sân</th>
                        <th>Mô tả</th>
                        <th>Hướng</th>
                        <th>Trạng thái</th>
                        <th>Hành động</th>
                    </tr>
                </thead>
                <tbody>
                    {playgrounds.map((playground) => (
                        <tr key={playground._id}>
                            <td>{playground.name}</td>
                            <td>{playground.description}</td>
                            <td>{playground.orientation}</td>
                            <td>{playground.status}</td>
                            <td>
                                <Button
                                    variant="warning"
                                    className="me-2"
                                    onClick={() => openModal(playground)}
                                >
                                    Sửa
                                </Button>
                                <Button
                                    variant="danger"
                                    onClick={() => handleDeletePlayground(playground._id)}
                                >
                                    Xóa
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <div className="mt-4">
                <h3>Thông tin cơ bản</h3>
                {stadiumInfo && (
                    <>
                        <Image src={`${process.env.REACT_APP_API_BASE_URL}/${stadiumInfo.avatar}`} alt="Avatar" rounded width="150" />
                        <Form.Group>
                            <Form.Label>Thay đổi ảnh đại diện</Form.Label>
                            <Form.Control type="file" onChange={(e) => setNewImage(e.target.files[0])} />
                            <Button className="mt-2" onClick={handleAvatarUpload}>Upload ảnh đại diện</Button>
                        </Form.Group>

                        <p>Địa chỉ: {stadiumInfo.address}</p>
                        <p>Link Google Map: <a href={stadiumInfo.googleMapLink} target="_blank" rel="noopener noreferrer">{stadiumInfo.googleMapLink}</a></p>
                        <p>Số điện thoại: {stadiumInfo.contactPhone}</p>
                        <p>Link YouTube: <a href={stadiumInfo.youtubeLink} target="_blank" rel="noopener noreferrer">{stadiumInfo.youtubeLink}</a></p>
                        <p>Người đại diện: {stadiumInfo.representativeName}</p>
                        <p>Giới thiệu: {stadiumInfo.introduction}</p>
                        <p>Bảng giá: {stadiumInfo.pricingTable}</p>
                        <Button onClick={() => setShowStadiumModal(true)}>Chỉnh sửa thông tin</Button>

                        <h4 className="mt-4">Danh sách ảnh</h4>
                        <div className="d-flex flex-wrap">
                            {stadiumInfo.imageLinks.map((image, index) => (
                                <div key={index} className="m-2">
                                    <Image src={`${process.env.REACT_APP_API_BASE_URL}/${image}`} alt="Gallery" thumbnail width="150" />
                                    <Button variant="danger" size="sm" className="mt-2" onClick={() => handleDeleteImage(image)}>Xóa ảnh</Button>
                                </div>
                            ))}
                        </div>

                        <Form.Group className="mt-4">
                            <Form.Label>Thêm hình ảnh</Form.Label>
                            <Form.Control type="file" onChange={(e) => setNewGalleryImage(e.target.files[0])} />
                            <Button onClick={handleGalleryImageUpload}>Upload Image</Button>
                        </Form.Group>
                    </>
                )}
            </div>
            

            {/* Modal chỉnh sửa thông tin BQL Sân */}
            <Modal show={showStadiumModal} onHide={() => setShowStadiumModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Chỉnh sửa thông tin BQL Sân</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="address">
                            <Form.Label>Địa chỉ</Form.Label>
                            <Form.Control
                                type="text"
                                value={stadiumInfo?.address || ''}
                                onChange={(e) => setStadiumInfo({ ...stadiumInfo, address: e.target.value })}
                            />
                        </Form.Group>

                        <Form.Group controlId="googleMapLink">
                            <Form.Label>Link Google Map</Form.Label>
                            <Form.Control
                                type="text"
                                value={stadiumInfo?.googleMapLink || ''}
                                onChange={(e) => setStadiumInfo({ ...stadiumInfo, googleMapLink: e.target.value })}
                            />
                        </Form.Group>

                        <Form.Group controlId="contactPhone">
                            <Form.Label>Số điện thoại</Form.Label>
                            <Form.Control
                                type="text"
                                value={stadiumInfo?.contactPhone || ''}
                                onChange={(e) => setStadiumInfo({ ...stadiumInfo, contactPhone: e.target.value })}
                            />
                        </Form.Group>

                        <Form.Group controlId="youtubeLink">
                            <Form.Label>Link YouTube</Form.Label>
                            <Form.Control
                                type="text"
                                value={stadiumInfo?.youtubeLink || ''}
                                onChange={(e) => setStadiumInfo({ ...stadiumInfo, youtubeLink: e.target.value })}
                            />
                        </Form.Group>

                        <Form.Group controlId="representativeName">
                            <Form.Label>Người đại diện</Form.Label>
                            <Form.Control
                                type="text"
                                value={stadiumInfo?.representativeName || ''}
                                onChange={(e) => setStadiumInfo({ ...stadiumInfo, representativeName: e.target.value })}
                            />
                        </Form.Group>

                        <Form.Group controlId="introduction">
                            <Form.Label>Giới thiệu</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                value={stadiumInfo?.introduction || ''}
                                onChange={(e) => setStadiumInfo({ ...stadiumInfo, introduction: e.target.value })}
                            />
                        </Form.Group>

                        <Form.Group controlId="pricingTable">
                            <Form.Label>Bảng giá</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                value={stadiumInfo?.pricingTable || ''}
                                onChange={(e) => setStadiumInfo({ ...stadiumInfo, pricingTable: e.target.value })}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowStadiumModal(false)}>Đóng</Button>
                    <Button variant="primary" onClick={handleSaveStadiumInfo}>Lưu thay đổi</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{editingPlayground ? 'Sửa sân chơi' : 'Thêm sân chơi mới'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="name">
                            <Form.Label>Tên sân</Form.Label>
                            <Form.Control
                                type="text"
                                value={editingPlayground ? editingPlayground.name : newPlayground.name}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (editingPlayground) {
                                        setEditingPlayground((prev) => ({ ...prev, name: value }));
                                    } else {
                                        setNewPlayground((prev) => ({ ...prev, name: value }));
                                    }
                                }}
                            />
                        </Form.Group>

                        <Form.Group controlId="description">
                            <Form.Label>Mô tả</Form.Label>
                            <Form.Control
                                as="textarea"
                                value={editingPlayground ? editingPlayground.description : newPlayground.description}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (editingPlayground) {
                                        setEditingPlayground((prev) => ({ ...prev, description: value }));
                                    } else {
                                        setNewPlayground((prev) => ({ ...prev, description: value }));
                                    }
                                }}
                            />
                        </Form.Group>

                        <Form.Group controlId="orientation">
                            <Form.Label>Hướng</Form.Label>
                            <Form.Control
                                type="text"
                                value={editingPlayground ? editingPlayground.orientation : newPlayground.orientation}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (editingPlayground) {
                                        setEditingPlayground((prev) => ({ ...prev, orientation: value }));
                                    } else {
                                        setNewPlayground((prev) => ({ ...prev, orientation: value }));
                                    }
                                }}
                            />
                        </Form.Group>

                        <Form.Group controlId="status">
                            <Form.Label>Trạng thái</Form.Label>
                            <Form.Control
                                as="select"
                                value={editingPlayground ? editingPlayground.status : newPlayground.status}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (editingPlayground) {
                                        setEditingPlayground((prev) => ({ ...prev, status: value }));
                                    } else {
                                        setNewPlayground((prev) => ({ ...prev, status: value }));
                                    }
                                }}
                            >
                                <option value="Sẵn sàng cho thuê">Sẵn sàng cho thuê</option>
                                <option value="Không sẵn sàng cho thuê">Không sẵn sàng cho thuê</option>
                            </Form.Control>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>Đóng</Button>
                    <Button variant="primary" onClick={handleSavePlayground}>
                        {editingPlayground ? 'Cập nhật' : 'Thêm mới'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default PlaygroundManagementListPage;
