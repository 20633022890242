import React, { useState } from 'react';
import axios from '../api/api'; // Sử dụng axios từ api.js

function ForgotPasswordPage() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('/users/forgot-password', { email });
            setMessage(response.data.message);
        } catch (error) {
            setMessage(error.response?.data?.message || 'Có lỗi xảy ra.');
        }
    };

    return (
        <div className="container mt-5">
            <h2>Quên mật khẩu</h2>

            {/* Giải thích cho người dùng về quy trình đặt lại mật khẩu */}
            <p style={{ fontStyle: 'italic', color: '#555' }}>
                Vui lòng nhập email của bạn vào ô bên dưới. Sau khi gửi yêu cầu, 
                bạn sẽ nhận được một email từ <strong>picklehub.xyz</strong> chứa 
                liên kết để đặt lại mật khẩu. Hãy kiểm tra hộp thư đến (hoặc hộp thư rác) 
                của bạn và nhấn vào liên kết để tiếp tục quá trình đặt lại mật khẩu.
            </p>

            {/* Hiển thị thông báo sau khi gửi email */}
            {message && <div className="alert alert-info">{message}</div>}

            {/* Form nhập email */}
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label className="form-label">Email:</label>
                    <input 
                        type="email" 
                        className="form-control" 
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)} 
                        required 
                        placeholder="Nhập email của bạn" // Thêm placeholder
                    />
                </div>
                <button type="submit" className="btn btn-primary">Gửi link đặt lại mật khẩu</button>
            </form>
        </div>
    );
}

export default ForgotPasswordPage;
