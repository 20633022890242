import React, { useState, useEffect } from 'react';
import axios from './../../../api/api';
import { Button, Form, Table, Container, Modal } from 'react-bootstrap';

const CoachListPage = () => {
    const [coaches, setCoaches] = useState([]);
    const [newCoach, setNewCoach] = useState({
        nick: '',
        fullName: '',
        sportId: '',
        phoneNumber: '',
        zaloLink: '',
        facebookLink: '',
        introduction: '',
        workingUnit: '',
        teachingArea: '',
        avatar: ''
    });
    const [editingCoach, setEditingCoach] = useState(null);
    const [showModal, setShowModal] = useState(false);  // Modal state
    const [deleteId, setDeleteId] = useState(null);  // ID để xác nhận xóa

    // Lấy tất cả các huấn luyện viên
    useEffect(() => {
        console.log(process.env.REACT_APP_API_BASE_URL);

        fetchCoaches();
    }, []);

    const fetchCoaches = async () => {
        const response = await axios.get('/coaches');
        setCoaches(response.data);
    };

    // Thêm hoặc cập nhật huấn luyện viên
    const handleSaveCoach = async () => {
        if (editingCoach) {
            await axios.put(`/coaches/${editingCoach._id}`, editingCoach);
        } else {
            await axios.post('/coaches', newCoach);
            setNewCoach({
                nick: '',
                fullName: '',
                sportId: '',
                phoneNumber: '',
                zaloLink: '',
                facebookLink: '',
                introduction: '',
                workingUnit: '',
                teachingArea: '',
                avatar: ''
            });
        }
        setShowModal(false);
        setEditingCoach(null);
        fetchCoaches();
    };

    // Xóa huấn luyện viên
    const handleDeleteCoach = async (id) => {
        await axios.delete(`/coaches/${id}`);
        setDeleteId(null);
        fetchCoaches();
    };

    // Mở Modal cho thêm hoặc sửa
    const openModal = (coach = null) => {
        setEditingCoach(coach);
        setNewCoach(coach || {
            nick: '',
            fullName: '',
            sportId: '',
            phoneNumber: '',
            zaloLink: '',
            facebookLink: '',
            introduction: '',
            workingUnit: '',
            teachingArea: '',
            avatar: ''
        });
        setShowModal(true);
    };

    // Đóng Modal
    const closeModal = () => {
        setEditingCoach(null);
        setShowModal(false);
    };

    // Upload ảnh
    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('avatar', file);  // 'avatar' là tên của trường file trong backend

        try {
            const response = await axios.post('/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            const fileUrl = response.data.url;
            if (editingCoach) {
                setEditingCoach({ ...editingCoach, avatar: fileUrl });
            } else {
                setNewCoach({ ...newCoach, avatar: fileUrl });
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    // Xác nhận xóa
    const confirmDelete = (id) => {
        setDeleteId(id);
    };

    return (
        <div>
            <h2 className="my-4">Coach Management</h2>

            <Button variant="primary" className="mb-4" onClick={() => openModal()}>Create New Coach</Button>

            <h3>Existing Coaches</h3>
            <Table striped bordered hover>
                <thead>
                    <tr>
                         <th>Nick</th>
                        <th>Name</th>
                        <th>Phone</th>
                        <th>Avatar</th>
                        <th>Introduction</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {coaches.map((coach) => (
                        <tr key={coach._id}>
                            <td>{coach.nick}</td> {/* Access coach name */}
                            <td>{coach.fullName}</td> {/* Access coach name */}
                            <td>{coach.phoneNumber}</td> {/* Access coach description */}
                            <td>{coach.introduction}</td> {/* Access coach description */}
                            <td>
                                <img src={`${process.env.REACT_APP_API_BASE_URL}/${coach.avatar}`} alt="Avatar" width="100" /> {/* Render avatar */}
                            </td>
                            <td>
                                <Button
                                    variant="warning"
                                    className="me-2"
                                    onClick={() => openModal(coach)}
                                >
                                    Edit
                                </Button>
                                <Button
                                    variant="danger"
                                    onClick={() => confirmDelete(coach._id)}
                                >
                                    Delete
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {/* Modal cho thêm/sửa huấn luyện viên */}
            <Modal show={showModal} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{editingCoach ? 'Edit Coach' : 'Create New Coach'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Nick</Form.Label>
                            <Form.Control
                                type="text"
                                value={editingCoach ? editingCoach.nick : newCoach.nick}
                                onChange={(e) => {
                                    if (editingCoach) {
                                        setEditingCoach({ ...editingCoach, nick: e.target.value });
                                    } else {
                                        setNewCoach({ ...newCoach, nick: e.target.value });
                                    }
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Full Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={editingCoach ? editingCoach.fullName : newCoach.fullName}
                                onChange={(e) => {
                                    if (editingCoach) {
                                        setEditingCoach({ ...editingCoach, fullName: e.target.value });
                                    } else {
                                        setNewCoach({ ...newCoach, fullName: e.target.value });
                                    }
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Sport ID</Form.Label>
                            <Form.Control
                                type="text"
                                value={editingCoach ? editingCoach.sportId : newCoach.sportId}
                                onChange={(e) => {
                                    if (editingCoach) {
                                        setEditingCoach({ ...editingCoach, sportId: e.target.value });
                                    } else {
                                        setNewCoach({ ...newCoach, sportId: e.target.value });
                                    }
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control
                                type="text"
                                value={editingCoach ? editingCoach.phoneNumber : newCoach.phoneNumber}
                                onChange={(e) => {
                                    if (editingCoach) {
                                        setEditingCoach({ ...editingCoach, phoneNumber: e.target.value });
                                    } else {
                                        setNewCoach({ ...newCoach, phoneNumber: e.target.value });
                                    }
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Zalo Link</Form.Label>
                            <Form.Control
                                type="text"
                                value={editingCoach ? editingCoach.zaloLink : newCoach.zaloLink}
                                onChange={(e) => {
                                    if (editingCoach) {
                                        setEditingCoach({ ...editingCoach, zaloLink: e.target.value });
                                    } else {
                                        setNewCoach({ ...newCoach, zaloLink: e.target.value });
                                    }
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Facebook Link</Form.Label>
                            <Form.Control
                                type="text"
                                value={editingCoach ? editingCoach.facebookLink : newCoach.facebookLink}
                                onChange={(e) => {
                                    if (editingCoach) {
                                        setEditingCoach({ ...editingCoach, facebookLink: e.target.value });
                                    } else {
                                        setNewCoach({ ...newCoach, facebookLink: e.target.value });
                                    }
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Introduction</Form.Label>
                            <Form.Control
                                type="text"
                                value={editingCoach ? editingCoach.introduction : newCoach.introduction}
                                onChange={(e) => {
                                    if (editingCoach) {
                                        setEditingCoach({ ...editingCoach, introduction: e.target.value });
                                    } else {
                                        setNewCoach({ ...newCoach, introduction: e.target.value });
                                    }
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Working Unit</Form.Label>
                            <Form.Control
                                type="text"
                                value={editingCoach ? editingCoach.workingUnit : newCoach.workingUnit}
                                onChange={(e) => {
                                    if (editingCoach) {
                                        setEditingCoach({ ...editingCoach, workingUnit: e.target.value });
                                    } else {
                                        setNewCoach({ ...newCoach, workingUnit: e.target.value });
                                    }
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Teaching Area</Form.Label>
                            <Form.Control
                                type="text"
                                value={editingCoach ? editingCoach.teachingArea : newCoach.teachingArea}
                                onChange={(e) => {
                                    if (editingCoach) {
                                        setEditingCoach({ ...editingCoach, teachingArea: e.target.value });
                                    } else {
                                        setNewCoach({ ...newCoach, teachingArea: e.target.value });
                                    }
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Avatar</Form.Label>
                            <Form.Control type="file" onChange={handleFileChange} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>Close</Button>
                    <Button variant="primary" onClick={handleSaveCoach}>
                        {editingCoach ? 'Save Changes' : 'Create Coach'}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal xác nhận xóa */}
            <Modal show={!!deleteId} onHide={() => setDeleteId(null)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this coach?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setDeleteId(null)}>Cancel</Button>
                    <Button variant="danger" onClick={() => handleDeleteCoach(deleteId)}>Delete</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default CoachListPage;
