import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import './ClubList.css';
import axios from './../api/api';
import { Link } from 'react-router-dom';


function ClubList() {
    const [clubs, setClubs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const clubsPerPage = 10;

    useEffect(() => {
        const fetchClubs = async () => {
            try {
                const response = await axios.get('/clubs/clubs-paginated', {
                    params: {
                        page: currentPage,
                        limit: clubsPerPage,
                        search: searchQuery
                    }
                });
                setClubs(response.data.clubs);
                setTotalPages(response.data.totalPages);
            } catch (error) {
                console.error('Error fetching clubs:', error);
            }
        };

        fetchClubs();
    }, [currentPage, searchQuery]);

    const handleSearch = () => {
        setSearchQuery(searchTerm);
        setCurrentPage(1);
    };

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <Container>
            <div className="club-list">
                <div className="search-bar" style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder="Search for clubs by name or description..."
                        style={{ padding: '10px', width: '300px', marginRight: '10px' }}
                    />
                    <button onClick={handleSearch} style={{ padding: '10px 20px' }}>Search</button>
                </div>

                {clubs.length > 0 ? (
                    clubs.map(club => (
                        <div key={club._id} className="club-list__item">
                            <img src={`${process.env.REACT_APP_API_BASE_URL}/${club.avatar}`} alt={club.name} />
                            <div className="club-list__info">
                                <h3>{club.name}</h3>
                                <p><strong>Address:</strong> {club.location}</p>
                                <p><strong>Representative:</strong> {club.president}</p>
                                <p><strong>Description:</strong> {club.introduction}</p>
                                <Link to={`/clubs/${club._id}/members`} className="member-link">
                                    Danh sách thành viên
                                </Link>
                            </div>
                        </div>
                    ))
                ) : (
                    <p>No clubs found.</p>
                )}

                <div className="club-list__pagination">
                    <button onClick={prevPage} disabled={currentPage === 1}>Previous</button>
                    <span>Page {currentPage} of {totalPages}</span>
                    <button onClick={nextPage} disabled={currentPage === totalPages}>Next</button>
                </div>
            </div>
        </Container>
    );
}

export default ClubList;
