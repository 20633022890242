import React, { useEffect, useState } from 'react';
import axios from './../api/api';
import { useNavigate } from 'react-router-dom'; // Use useNavigate instead of useHistory

const VideoListCarousel = ({ videoConfigValue }) => {
    const [videos, setVideos] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const navigate = useNavigate(); // Use useNavigate to navigate

    useEffect(() => {
        const fetchVideos = async () => {
            try {
                const linksArray = videoConfigValue.slice(1, -1).split(',');
                const sanitizedLinks = linksArray.map(link => link.trim());

                const response = await axios.get('/videos/videos-by-links', {
                    params: { links: sanitizedLinks }
                });

                setVideos(response.data);
            } catch (error) {
                console.error('Error fetching videos:', error);
            }
        };

        if (videoConfigValue) {
            fetchVideos();
        }
    }, [videoConfigValue]);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 2) % videos.length);
        }, 5000);
        return () => clearInterval(interval);
    }, [videos.length]);

    const displayedVideos = videos.slice(currentIndex, currentIndex + 2);

    // Function to handle video click and navigate to the video detail page
    const handleVideoClick = (youtubeLink) => {
        const videoId = new URLSearchParams(new URL(youtubeLink).search).get('v');
        navigate(`/video-detail/${videoId}`); // Navigate to video detail page with video ID
    };

    return (
        
        <div className="video-carousel">
            <div style={{
                backgroundColor: '#007BFF', // Màu nền xanh
                color: 'white',              // Chữ trắng
                textAlign: 'center',         // Canh giữa
                padding: '10px',             // Khoảng cách trên dưới
                fontSize: '1.5em',           // Kích thước chữ nhỏ hơn
            }}>
                Video nổi bật
            </div>
            <div className="video-pair" style={{ display: 'flex', justifyContent: 'space-between' }}>
                {displayedVideos.map((video, index) => (
                    <div
                        key={index}
                        className="video-item"
                        style={{ width: '48%', cursor: 'pointer' }}
                        onClick={() => handleVideoClick(video.youtubeLink)} // Navigate on click
                    >
                        <img
                            src={`https://img.youtube.com/vi/${new URLSearchParams(new URL(video.youtubeLink).search).get('v')}/0.jpg`}
                            alt={video.name}
                            style={{ width: '100%', height: 'auto' }}
                        />
                        <p>{video.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default VideoListCarousel;
