/*import axios from 'axios';

const api = axios.create({
  baseURL: 'http://localhost:5000/api',
});

const auth = sessionStorage.getItem('auth'); // Lấy auth từ sessionStorage
if (auth) {
    const parsedAuth = JSON.parse(auth); // Parse auth string thành object
    const token = parsedAuth.token; // Lấy token từ auth object
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}
  

export default api;
*/
// src/api/api.js
import axios from 'axios';

const api = axios.create({
    //baseURL: 'http://171.244.10.195:5003/api',
    baseURL: 'http://103.145.63.66:5003/api',
    //baseURL: 'http://192.168.1.13:5000/api',
});

api.interceptors.request.use(
    (config) => {
        const auth = JSON.parse(sessionStorage.getItem('auth'));
        if (auth && auth.token) {
            config.headers['Authorization'] = `Bearer ${auth.token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default api;
