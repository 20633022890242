import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../api/api';

function ResetPasswordPage() {
    const { token } = useParams();
    const [newPassword, setNewPassword] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`/users/reset-password/${token}`, { newPassword });
            setMessage(response.data.message);
        } catch (error) {
            setMessage(error.response?.data?.message || 'Có lỗi xảy ra.');
        }
    };

    return (
        <div className="container mt-5">
            <h2>Đặt lại mật khẩu</h2>
            {message && <div className="alert alert-info">{message}</div>}
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label className="form-label">Mật khẩu mới:</label>
                    <input 
                        type="password" 
                        className="form-control" 
                        value={newPassword} 
                        onChange={(e) => setNewPassword(e.target.value)} 
                        required 
                    />
                </div>
                <button type="submit" className="btn btn-primary">Đặt lại mật khẩu</button>
            </form>
        </div>
    );
}

export default ResetPasswordPage;
