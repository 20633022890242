import React, { useState, useEffect } from 'react';
import './ProgramList.css';
import axios from './../api/api'; // Đảm bảo bạn đã cấu hình axios

function ProgramList() {
    const [programs, setPrograms] = useState([]); // Dữ liệu giải đấu
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1); // Tổng số trang
    const [searchTerm, setSearchTerm] = useState(''); // Từ khóa tìm kiếm
    const [searchQuery, setSearchQuery] = useState(''); // Từ khóa được tìm
    const programsPerPage = 10;

    // Lấy danh sách giải đấu từ API với phân trang và tìm kiếm
    useEffect(() => {
        const fetchPrograms = async () => {
            try {
                const response = await axios.get('/tournaments/tournaments-paginated', {
                    params: {
                        page: currentPage,
                        limit: programsPerPage,
                        search: searchQuery // Truyền từ khóa tìm kiếm lên API
                    }
                });
                setPrograms(response.data.tournaments); // Cập nhật danh sách giải đấu
                setTotalPages(response.data.totalPages); // Cập nhật tổng số trang
            } catch (error) {
                console.error('Error fetching tournaments:', error);
            }
        };

        fetchPrograms();
    }, [currentPage, searchQuery]); // Gọi lại khi trang hoặc từ khóa tìm kiếm thay đổi

    // Xử lý tìm kiếm giải đấu
    const handleSearch = () => {
        setSearchQuery(searchTerm); // Cập nhật từ khóa tìm kiếm và gọi API
        setCurrentPage(1); // Đặt lại về trang đầu tiên khi tìm kiếm
    };

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <div className="program-list container">
            {/* Thanh tìm kiếm */}
            <div className="search-bar" style={{ textAlign: 'center', marginBottom: '20px' }}>
                <input 
                    type="text" 
                    value={searchTerm} 
                    onChange={(e) => setSearchTerm(e.target.value)} 
                    placeholder="Search for tournaments by name or summary..." 
                    style={{ width: '60%', padding: '10px', fontSize: '16px' }}
                />
                <button 
                    onClick={handleSearch} 
                    style={{ padding: '10px 20px', marginLeft: '10px', fontSize: '16px' }}
                >
                    Search
                </button>
            </div>

            {/* Danh sách giải đấu */}
            {programs.length > 0 ? (
                programs.map(program => (
                    <div key={program._id} className="program-item">
                        <div className="program-content">
                            {/* Hiển thị avatar bên trái */}
                            <div className="program-avatar">
                                <img 
                                    src={`${process.env.REACT_APP_API_BASE_URL}/${program.avatar}`} 
                                    alt={program.name} 
                                    style={{ width: '150px', height: '150px', objectFit: 'cover', borderRadius: '8px' }} 
                                />
                            </div>
                            {/* Thông tin giải đấu */}
                            <div className="program-info">
                                <h3>{program.name}</h3>
                                <p><strong>Description:</strong> {program.summary}</p>
                                <p><strong>Start Date:</strong> {new Date(program.expectedStartDate).toLocaleDateString()}</p>
                                <p><strong>End Date:</strong> {new Date(program.expectedEndDate).toLocaleDateString()}</p>
                                <p><strong>Location:</strong> {program.location}</p>
                                <p><strong>Organizer:</strong> {program.organizingUnit}</p>
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                <p>No tournaments found.</p>
            )}

            {/* Phân trang */}
            <div className="pagination">
                <button onClick={prevPage} disabled={currentPage === 1}>Previous</button>
                <span>Page {currentPage} of {totalPages}</span>
                <button onClick={nextPage} disabled={currentPage === totalPages}>Next</button>
            </div>
        </div>
    );
}

export default ProgramList;
