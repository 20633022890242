import React, { useEffect, useState } from 'react';
import axios from './../api/api';
import Slider from 'react-slick'; // Thêm import Slider từ react-slick

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ClubHomepage = ({ clubConfigValue }) => {
    const [clubs, setClubs] = useState([]);

    useEffect(() => {
        const fetchClubs = async () => {
            try {
                const clubIdsArray = clubConfigValue.slice(1, -1).split(',');
                const sanitizedIds = clubIdsArray.map(id => id.trim());

                const response = await axios.get('/clubs/clubs-by-ids', {
                    params: { ids: sanitizedIds }
                });

                setClubs(response.data);
            } catch (error) {
                console.error('Error fetching clubs:', error);
            }
        };

        if (clubConfigValue) {
            fetchClubs();
        }
    }, [clubConfigValue]);

    // Cấu hình cho slider
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2, // Hiển thị 2 câu lạc bộ trên mỗi dòng
        slidesToScroll: 1, // Trượt một lần 1 nhóm
        autoplay: true,
        autoplaySpeed: 6000, // Tự động chuyển sau 6 giây
    };

    return (
        <div>
            <div style={{
                backgroundColor: '#007BFF', // Màu nền xanh
                color: 'white',              // Chữ trắng
                textAlign: 'center',         // Canh giữa
                padding: '10px',             // Khoảng cách trên dưới
                fontSize: '1.5em',           // Kích thước chữ nhỏ hơn
            }}>
                Câu lạc bộ ấn tượng
            </div>
            {clubs.length > 0 && (
                <Slider {...settings}>
                    {clubs.map((club, index) => (
                        <div key={index} style={{ padding: '10px', textAlign: 'center' }}>
                            <img
                                src={`${process.env.REACT_APP_API_BASE_URL}/${club.avatar}`}
                                alt={club.name}
                                style={{
                                    width: '100%',
                                    height: '250px',        // Set a fixed height
                                    objectFit: 'cover',     // Maintain aspect ratio and fill the container
                                    objectPosition: 'center' // Ensures the center of the image is prioritized
                                }}
                            />
                            <h3>{club.name}</h3>
                            <p><strong>President:</strong> {club.president}</p>
                            <p><strong>Location:</strong> {club.location}</p>
                        </div>
                    ))}
                </Slider>
            )}
        </div>
    );
};

export default ClubHomepage;
