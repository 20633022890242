import React, { useState, useEffect } from 'react';
import axios from './../../../api/api';
import { Form, Button, Image, Container } from 'react-bootstrap';

const defaultAvatar = 'https://via.placeholder.com/150'; // Ảnh mặc định nếu không có ảnh đại diện

const Profile = () => {
    const [user, setUser] = useState(null);
    const [newAvatar, setNewAvatar] = useState(null);
    const [fullName, setFullName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [avatarPreview, setAvatarPreview] = useState(null); // Để hiện ảnh mới khi upload

    useEffect(() => {
        // Lấy thông tin người dùng hiện tại
        const fetchUserProfile = async () => {
            try {
                const response = await axios.get('/users/profile'); // Endpoint lấy thông tin người dùng
                setUser(response.data);
                setFullName(response.data.fullName || '');
                setPhoneNumber(response.data.phoneNumber || '');
            } catch (error) {
                console.error('Error fetching profile:', error);
            }
        };

        fetchUserProfile();
    }, []);

    const handleAvatarUpload = async () => {
        if (!newAvatar) return;
        const formData = new FormData();
        formData.append('avatar', newAvatar);
    
        try {
            await axios.put('/users/upload-avatar', formData); // Endpoint cập nhật ảnh đại diện
            window.location.reload(); // Tải lại trang sau khi cập nhật ảnh thành công
        } catch (error) {
            console.error('Error uploading avatar:', error);
        }
    };

    const handleProfileUpdate = async () => {
        try {
            await axios.put('/users/profile', { fullName, phoneNumber }); // Endpoint cập nhật thông tin
            window.alert('Cập nhật hồ sơ thành công');
        } catch (error) {
            console.error('Error updating profile:', error);
        }
    };

    // Hiển thị ảnh xem trước khi người dùng chọn ảnh mới
    const handleAvatarChange = (e) => {
        const file = e.target.files[0];
        setNewAvatar(file);
        setAvatarPreview(URL.createObjectURL(file)); // Tạo URL tạm thời để xem trước ảnh
    };

    if (!user) {
        return <p>Đang tải thông tin...</p>;
    }

    return (
        <div>
            <h2>Thông tin hồ sơ</h2>

            {/* Hiển thị ảnh đại diện */}
            <Image
                src={avatarPreview || `${process.env.REACT_APP_API_BASE_URL}/${user.avatar}` || defaultAvatar}
                roundedCircle
                width="150"
                alt="Avatar"
            />
            <Form.Group className="mt-3">
                <Form.Label>Thay đổi ảnh đại diện</Form.Label>
                <Form.Control type="file" onChange={handleAvatarChange} />
                <Button className="mt-2" onClick={handleAvatarUpload}>Cập nhật ảnh đại diện</Button>
            </Form.Group>

            <Form className="mt-4">
                {/* Không cho sửa */}
                <Form.Group>
                    <Form.Label>Username</Form.Label>
                    <Form.Control type="text" value={user.username} readOnly />
                </Form.Group>

                <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" value={user.email} readOnly />
                </Form.Group>

                {/* Cho phép sửa */}
                <Form.Group>
                    <Form.Label>Họ tên</Form.Label>
                    <Form.Control
                        type="text"
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                    />
                </Form.Group>

                <Form.Group>
                    <Form.Label>Điện thoại</Form.Label>
                    <Form.Control
                        type="text"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                </Form.Group>

                <Button className="mt-3" onClick={handleProfileUpdate}>
                    Cập nhật hồ sơ
                </Button>
            </Form>
        </div>
    );
};

export default Profile;
