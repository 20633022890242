import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Để điều hướng
import axios from './../api/api'; // Điều chỉnh theo cấu trúc API của bạn
import './VideoList.css'; // Tạo file CSS để định dạng

function VideoList() {
    const [videos, setVideos] = useState([]); // Dữ liệu video
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1); // Tổng số trang
    const videosPerPage = 20; // 20 videos trên mỗi trang
    const navigate = useNavigate(); // Để điều hướng đến VideoDetail

    // Lấy danh sách video từ API với phân trang
    useEffect(() => {
        const fetchVideos = async () => {
            try {
                const response = await axios.get('/videos/videos-paginated', {
                    params: {
                        page: currentPage,
                        limit: videosPerPage
                    }
                });
                setVideos(response.data.videos); // Cập nhật danh sách video
                setTotalPages(response.data.totalPages); // Cập nhật tổng số trang
            } catch (error) {
                console.error('Error fetching videos:', error);
            }
        };

        fetchVideos();
    }, [currentPage]);

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleVideoClick = (youtubeLink) => {
        const videoId = new URLSearchParams(new URL(youtubeLink).search).get('v');
        navigate(`/video-detail/${videoId}`); // Navigate to video detail page with video ID
    };

    return (
        <div className="video-list container">
            <div className="video-grid">
                {videos.length > 0 ? (
                    videos.map(video => (
                        <div 
                            key={video._id} 
                            className="video-item"
                            onClick={() => handleVideoClick(video.youtubeLink)} // Khi nhấn vào video
                        >
                            <img 
                                src={`https://img.youtube.com/vi/${video.youtubeLink.split('v=')[1]}/0.jpg`} 
                                alt={video.name} 
                                className="video-thumbnail"
                            />
                            <div className="video-info">
                                <h3>{video.name}</h3>
                                <p>{video.description}</p>
                            </div>
                        </div>
                    ))
                ) : (
                    <p>No videos found.</p>
                )}
            </div>

            <div className="pagination">
                <button onClick={prevPage} disabled={currentPage === 1}>Previous</button>
                <span>Page {currentPage} of {totalPages}</span>
                <button onClick={nextPage} disabled={currentPage === totalPages}>Next</button>
            </div>
        </div>
    );
}

export default VideoList;
