import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Dashboard from './Dashboard';  // Import Dashboard
import Settings from './Settings';  // Import Settings
import UserManagement from './UserManagement';  // Import User Management
import SportListPage from './Sports/SportListPage';  // Import  SportListPage
import ArticleListPage from './Article/ArticleListPage';  // Import User Management
import ArticleContentListPage from './Article/ArticleContentListPage';  // Import User Management
import CoachListPage from './Coach/CoachListPage';  // Import User Management
import ClubListPage from './Club/ClubListPage';  // Import User Management
import RefereeListPage from './Referee/RefereeListPage';  // Import User Management
import VideoManagement from './Video/VideoManagement';  // Import User Management
import TournamentListPage from './Tournament/TournamentListPage';  // Import User Management
import StadiumManagementListPage from './Stadium/StadiumManagementListPage';  // Import User Management
import ClubRegistrationManagement from './Club/ClubRegistrationManagement';  // Import User Management
import ConfigListPage from './Config/ConfigListPage';  // Import User Management
function AdminLayout() {
    return (
        <div className="main-content">
            <Routes>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/configurations" element={<ConfigListPage />} />
                <Route path="/users" element={<UserManagement />} />
                <Route path="/sports" element={<SportListPage />} />
                <Route path="/articles" element={<ArticleListPage />} />
                <Route path="/coaches" element={<CoachListPage />} />
                <Route path="/clubs" element={<ClubListPage />} />
                <Route path="/club-registration" element={<ClubRegistrationManagement />} />
                <Route path="/referees" element={<RefereeListPage />} />
                <Route path="/videos" element={<VideoManagement />} />
                <Route path="/tournaments" element={<TournamentListPage />} />
                <Route path="/stadiums" element={<StadiumManagementListPage />} />
                <Route path="/articles/:articleId/contents" element={<ArticleContentListPage />} />
                {/* Thêm các routes khác ở đây */}
            </Routes>
        </div>
    );
}

export default AdminLayout;
