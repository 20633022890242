import React from 'react';
import './Contact.css';

function Contact() {
    return (
        <div className="contact-page container">
            <h2>Liên hệ với chúng tôi</h2>
            <p>PickleHub là nền tảng hàng đầu kết nối những người yêu thích Pickleball. Tại đây, bạn có thể dễ dàng tìm kiếm các dịch vụ huấn luyện viên, trọng tài, đặt sân thi đấu, và tham gia các trận giao lưu với cộng đồng người chơi khắp cả nước. Ngoài ra, chúng tôi còn cung cấp hệ thống xếp hạng và tính điểm chuyên nghiệp, giúp bạn theo dõi tiến trình và nâng cao kỹ năng.

Hãy tham gia PickleHub để trải nghiệm trọn vẹn thế giới Pickleball và kết nối với những người đam mê như bạn!
            </p>
            
            <div className="contact-info">
                <h3>Nhóm dự án</h3>
                <p>Anh em yêu thích Pickcleball Bắc Giang - Hà Nội</p>
                <p>Bắc Giang - Hà Nội</p>
                <p>Việt Nam</p>

                <h3>Email: </h3>
                <p>contact@picklehub.xyz</p>

                <h3>Phone</h3>
                <p>+84 912539102</p>
            </div>
            {/*
            <div className="contact-form">
                <h3>Send Us a Message</h3>
                <form>
                    <label htmlFor="name">Name:</label>
                    <input type="text" id="name" name="name" placeholder="Your Name" />

                    <label htmlFor="email">Email:</label>
                    <input type="email" id="email" name="email" placeholder="Your Email" />

                    <label htmlFor="message">Message:</label>
                    <textarea id="message" name="message" placeholder="Your Message"></textarea>

                    <button type="submit">Submit</button>
                </form>
            </div>*/}
        </div>
    );
}

export default Contact;
