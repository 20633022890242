import React, { useState, useEffect } from 'react';
import { Form, Button, Table, Alert, Modal } from 'react-bootstrap';
import api from '../../../api/api';  // API helper

const ClubMemberManagement = () => {
  const [clubs, setClubs] = useState([]);
  const [selectedClub, setSelectedClub] = useState('');
  const [members, setMembers] = useState([]);
  const [error, setError] = useState('');
  const [inviteError, setInviteError] = useState('');  // For invite errors
  const [inviteSuccess, setInviteSuccess] = useState('');  // For invite success
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);  // For the edit modal
  const [editingMember, setEditingMember] = useState(null);  // Track member being edited
  const [inviteEmail, setInviteEmail] = useState('');  // Store invite email

  useEffect(() => {
    // Fetch the user's clubs
    const fetchClubs = async () => {
      try {
        const response = await api.get('/clubs/club-of-users');
        const userClubs = response.data.clubs;
        if (userClubs.length === 0) {
          setError("Bạn không được quản trị CLB nào.");
        } else {
          setClubs(userClubs);
          setSelectedClub(userClubs[0].clubId._id);  // Select the first club
          fetchMembers(userClubs[0].clubId._id);  // Load members
        }
      } catch (err) {
        setError("Lỗi khi tải dữ liệu CLB.");
      } finally {
        setLoading(false);
      }
    };
    fetchClubs();
  }, []);

  const fetchMembers = async (clubId) => {
    try {
      const response = await api.get(`/club-members/${clubId}/members`);
      setMembers(response.data);
    } catch (err) {
      setError("Lỗi khi tải dữ liệu thành viên.");
    }
  };

  const handleClubChange = (event) => {
    setSelectedClub(event.target.value);
    fetchMembers(event.target.value);
  };

  const handleApproveMember = async (memberId) => {
    if (window.confirm("Bạn có chắc chắn muốn duyệt thành viên này?")) {
      try {
        await api.put(`/club-members/${memberId}/approve`);
        fetchMembers(selectedClub);  // Refresh members
      } catch (err) {
        setError("Lỗi khi duyệt thành viên.");
      }
    }
  };

  const handleRejectMember = async (memberId) => {
    if (window.confirm("Bạn có chắc chắn muốn từ chối thành viên này?")) {
      try {
        await api.put(`/club-members/${memberId}/reject`);
        fetchMembers(selectedClub);  // Refresh members
      } catch (err) {
        setError("Lỗi khi từ chối thành viên.");
      }
    }
  };

  const handleEditMember = (member) => {
    setEditingMember(member);
    setShowModal(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const payload = {
        nick: event.target.nick.value,
      };

      await api.put(`/club-members/${editingMember._id}/edit-nick`, payload);
      fetchMembers(selectedClub);
      setShowModal(false);
    } catch (err) {
      setError("Lỗi khi sửa thông tin thành viên.");
    }
  };

  const handleInviteSubmit = async (event) => {
    if (window.confirm("Bạn có chắc chắn muốn mời thành viên này?")) {
      event.preventDefault();
      setInviteError('');  // Clear previous errors
      setInviteSuccess('');  // Clear previous success messages

      try {
        const response = await api.post(`/club-members/${selectedClub}/invite`, { email: inviteEmail });
        if (response.data.success) {
          setInviteSuccess(response.data.message);  // Show success message
          fetchMembers(selectedClub);  // Refresh members
        } else {
          setInviteError(response.data.message);  // Show error message
        }
      } catch (err) {
        setInviteError("Lỗi khi mời người dùng tham gia CLB.");
      }
    }
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div className="club-member-management">
      <h2>Quản lý thành viên CLB</h2>

      {error && <Alert variant="danger">{error}</Alert>}

      <Form.Group controlId="clubSelect">
        <Form.Label>Chọn Câu Lạc Bộ</Form.Label>
        <Form.Control as="select" value={selectedClub} onChange={handleClubChange}>
          {clubs.map(club => (
            <option key={club.clubId._id} value={club.clubId._id}>
              {club.clubId.name}
            </option>
          ))}
        </Form.Control>
      </Form.Group>

      {members.length > 0 ? (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Nick</th>
              <th>Họ và Tên</th>
              <th>Điện thoại</th>
              <th>Email</th>
              <th>Ảnh đại diện</th>
              <th>Trạng thái</th>
              <th>Hành động</th>
            </tr>
          </thead>
          <tbody>
            {members.map(member => (
              <tr key={member._id}>
                <td>{member.nickInClub}</td>
                <td>{member.userId.fullName}</td>
                <td>{member.userId.phoneNumber}</td>
                <td>{member.userId.email}</td>
                <td><img src={`${process.env.REACT_APP_API_BASE_URL}/${member.userId.avatar}`} alt="Avatar" width="100" /></td>
                <td>{member.status}</td>
                <td>
                  {member.status === 'draft' && (
                    <>
                      <Button variant="success" onClick={() => handleApproveMember(member._id)}>Duyệt</Button>
                      <Button variant="danger" onClick={() => handleRejectMember(member._id)} className="ml-2">Từ chối</Button>
                    </>
                  )}
                  {member.status === 'approved' && (
                    <Button variant="danger" onClick={() => handleRejectMember(member._id)}>Từ chối</Button>
                  )}
                  {member.status === 'rejected' && (
                    <Button variant="success" onClick={() => handleApproveMember(member._id)}>Duyệt</Button>
                  )}
                  <Button variant="warning" onClick={() => handleEditMember(member)} className="ml-2">Sửa nick</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p>Không có thành viên nào trong CLB này.</p>
      )}

      {/* Modal for editing member's nick */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Sửa Nick Thành Viên</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="nick">
              <Form.Label>Nick</Form.Label>
              <Form.Control type="text" defaultValue={editingMember?.nick || ''} required />
            </Form.Group>
            <Button variant="primary" type="submit">Cập nhật</Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Form to invite a user */}
      <h3>Mời người dùng tham gia CLB</h3>
      {inviteError && <Alert variant="danger">{inviteError}</Alert>}
      {inviteSuccess && <Alert variant="success">{inviteSuccess}</Alert>}
      <Form onSubmit={handleInviteSubmit}>
        <Form.Group controlId="inviteEmail">
          <Form.Label>Nhập email người dùng:</Form.Label>
          <Form.Control
            type="email"
            value={inviteEmail}
            onChange={(e) => setInviteEmail(e.target.value)}
            required
          />
        </Form.Group>
        <Button variant="primary" type="submit">Mời tham gia CLB</Button>
      </Form>
    </div>
  );
};

export default ClubMemberManagement;
