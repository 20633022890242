import React, { useState, useEffect } from 'react';
import axios from './../../../api/api';
import { Button, Form, Table, Container, Modal, Pagination } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const CustomerListPage = () => {
    const [customers, setCustomers] = useState([]);
    const [newCustomer, setNewCustomer] = useState({
        customerCode: '',
        fullName: '',
        phone: '',
        email: '',
        birthday: '',
        avatar: '',
        status: 'pending',
        note: ''
    });
    const [todos, setTodos] = useState([]);  // Danh sách todo cho khách hàng hiện tại
    const [newTodo, setNewTodo] = useState({ title: '', dueDate: '', notes: '' });
    const [editingTodo, setEditingTodo] = useState(null);  // Đang chỉnh sửa todo
    const [currentCustomerId, setCurrentCustomerId] = useState(null);  // ID khách hàng hiện tại

    const [showTodoModal, setShowTodoModal] = useState(false);  // Hiển thị modal ToDoList


    const [editingCustomer, setEditingCustomer] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [search, setSearch] = useState('');  // Ô tìm kiếm
    const [currentPage, setCurrentPage] = useState(1);  // Trang hiện tại
    const [totalPages, setTotalPages] = useState(1);  // Tổng số trang
    const navigate = useNavigate();

    useEffect(() => {
        fetchCustomers();
    }, [currentPage, search]);

    const fetchCustomers = async () => {
        const response = await axios.get(`/customers?page=${currentPage}&limit=5&search=${search}`);
        setCustomers(response.data.customers);
        setTotalPages(response.data.totalPages);
    };

    // Thêm hoặc cập nhật khách hàng
    // Thêm hoặc cập nhật khách hàng
    const handleSaveCustomer = async () => {
        try {
            // Không cần kiểm tra loại khách hàng khi chỉnh sửa
            const formData = new FormData();
            Object.keys(newCustomer).forEach(key => {
                formData.append(key, newCustomer[key]);
            });

            if (editingCustomer) {
                await axios.put(`/customers/${editingCustomer._id}`, formData);
            } else {
                if (!newCustomer.customerType) {
                    alert("Please select a customer type");
                    return;
                }
                await axios.post('/customers', formData);
            }

            setNewCustomer({
                customerType: '', fullName: '', phone: '', email: '', birthday: '', avatar: '', status: 'pending', note: ''
            });

            setShowModal(false);
            fetchCustomers();
        } catch (error) {
            if (error.response && error.response.data.message === 'Phone number already exists') {
                alert('Phone number already exists. Please use a different phone number.');
            } else {
                console.error('Error saving customer:', error);
            }
        }
    };



    // Xóa khách hàng
    const handleDeleteCustomer = async (id) => {
        await axios.delete(`/customers/${id}`);
        setDeleteId(null);
        fetchCustomers();
    };

    // Thay đổi file avatar
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setNewCustomer({ ...newCustomer, avatar: file });
    };

    // Mở Modal cho thêm hoặc sửa
    const openModal = (customer = null) => {
        setEditingCustomer(customer);
        setNewCustomer(customer || {
            customerCode: '', fullName: '', phone: '', email: '', birthday: '', avatar: '', status: 'pending', note: ''
        });
        setShowModal(true);
    };

    // Đóng Modal
    const closeModal = () => {
        setEditingCustomer(null);
        setShowModal(false);
    };

    // Xác nhận xóa
    const confirmDelete = (id) => {
        setDeleteId(id);
    };

    // Xử lý tìm kiếm
    const handleSearchChange = (e) => {
        setSearch(e.target.value);
        setCurrentPage(1);  // Khi tìm kiếm, reset về trang 1
    };

    // Phân trang
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Lấy danh sách Todo cho một khách hàng
    const fetchCustomerTodos = async (customerId) => {
        try {
            const response = await axios.get(`/customers/${customerId}/todos`);
            console.log(response.data); // Kiểm tra dữ liệu nhận được từ API
            setTodos(response.data); // Lưu dữ liệu vào state
            setCurrentCustomerId(customerId);
            setShowTodoModal(true);
        } catch (error) {
            console.error('Error fetching todos:', error);
        }
    };

    // Thêm hoặc cập nhật ToDo item
    const handleSaveTodo = async () => {
        if (editingTodo) {
            await axios.put(`/customers/${currentCustomerId}/todos/${editingTodo._id}`, newTodo);
        } else {
            await axios.post(`/customers/${currentCustomerId}/todos`, newTodo);
        }
        setNewTodo({ title: '', dueDate: '', notes: '' });
        setEditingTodo(null);
        fetchCustomerTodos(currentCustomerId);
    };

    // Xóa ToDo item
    const handleDeleteTodo = async (todoId) => {
        await axios.delete(`/customers/${currentCustomerId}/todos/${todoId}`);
        fetchCustomerTodos(currentCustomerId);
    };

    // Mở modal quản lý ToDo cho một khách hàng
    const openTodoModal = (customer) => {
        fetchCustomerTodos(customer._id);
    };

    return (
        <div>
            <h2 className="my-4">Customer Management</h2>

            {/* Ô tìm kiếm */}
            <Form.Group className="mb-3">
                <Form.Control
                    type="text"
                    placeholder="Search customers by name..."
                    value={search}
                    onChange={handleSearchChange}
                />
            </Form.Group>

            <Button variant="primary" className="mb-4" onClick={() => openModal()}>Create New Customer</Button>

            <h3>Existing Customers</h3>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Avatar</th>
                        <th>Code</th>
                        <th>Name</th>
                        <th>DoB</th>
                        <th>Phone</th>
                        <th>Email</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {customers && customers.length > 0 ? (
                        customers.map((customer) => (
                            <tr key={customer._id}>
                                <td>
                                    <img src={`${process.env.REACT_APP_API_BASE_URL}/${customer.avatar}`} alt="Avatar" width="100" />
                                </td>
                                <td>{customer.customerCode}</td>
                                <td>{customer.fullName}</td>
                                <td>{customer.birthday ? new Date(customer.birthday).toLocaleDateString() : 'N/A'}</td>
                                <td>{customer.phone}</td>
                                <td>{customer.email}</td>
                                <td>{customer.status}</td>
                                <td>
                                    <Button variant="warning" className="me-2" onClick={() => openModal(customer)}>Edit</Button>
                                    <Button variant="danger" onClick={() => confirmDelete(customer._id)}>Delete</Button>
                                    <Button variant="info" onClick={() => openTodoModal(customer)}>ToDo List</Button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="8" className="text-center">No customers found.</td>
                        </tr>
                    )}
                </tbody>
            </Table>

            {/* Phân trang */}
            <Pagination>
                {[...Array(totalPages).keys()].map(page => (
                    <Pagination.Item
                        key={page + 1}
                        active={page + 1 === currentPage}
                        onClick={() => handlePageChange(page + 1)}
                    >
                        {page + 1}
                    </Pagination.Item>
                ))}
            </Pagination>

            {/* Modal thêm/sửa khách hàng */}
            <Modal show={showModal} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{editingCustomer ? 'Edit Customer' : 'Create New Customer'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Customer Type</Form.Label>
                            <Form.Control
                                as="select"
                                value={newCustomer.customerType || ''}  // Đảm bảo không có giá trị null
                                onChange={(e) => setNewCustomer({ ...newCustomer, customerType: e.target.value })}
                            >
                                <option value="">Select Type</option>
                                <option value="SVG">Siêu Việt Group (SVG)</option>
                                <option value="HAN">Hãng (HAN)</option>
                                <option value="NPP">Nhà phân phối (NPP)</option>
                                <option value="SSI">SSI</option>
                                <option value="SMD">Đại lý phân phối (SMD)</option>
                                <option value="EUR">EUR</option>
                                <option value="OTH">Khác (OTH)</option>
                            </Form.Control>
                        </Form.Group>

                        {/* Chỉ hiển thị mã khách hàng khi chỉnh sửa */}
                        {editingCustomer && (
                            <Form.Group className="mb-3">
                                <Form.Label>Customer Code</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={newCustomer.customerCode || ''}  // Đảm bảo không có giá trị null
                                    readOnly  // Không cho phép chỉnh sửa mã khách hàng
                                />
                            </Form.Group>
                        )}
                        <Form.Group className="mb-3">
                            <Form.Label>Full Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={newCustomer.fullName}
                                onChange={(e) => setNewCustomer({ ...newCustomer, fullName: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Phone</Form.Label>
                            <Form.Control
                                type="text"
                                value={newCustomer.phone}
                                onChange={(e) => setNewCustomer({ ...newCustomer, phone: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="text"
                                value={newCustomer.email}
                                onChange={(e) => setNewCustomer({ ...newCustomer, email: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Birthday</Form.Label>
                            <Form.Control
                                type="date"
                                value={newCustomer.birthday}
                                onChange={(e) => setNewCustomer({ ...newCustomer, birthday: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Avatar</Form.Label>
                            <Form.Control type="file" onChange={handleFileChange} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Status</Form.Label>
                            <Form.Control
                                as="select"
                                value={newCustomer.status}
                                onChange={(e) => setNewCustomer({ ...newCustomer, status: e.target.value })}
                            >
                                <option value="pending">Pending</option>
                                <option value="approved">Approved</option>
                                <option value="rejected">Rejected</option>
                            </Form.Control>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>Close</Button>
                    <Button variant="primary" onClick={handleSaveCustomer}>
                        {editingCustomer ? 'Save Changes' : 'Create Customer'}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal xác nhận xóa */}
            <Modal show={!!deleteId} onHide={() => setDeleteId(null)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this customer?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setDeleteId(null)}>Cancel</Button>
                    <Button variant="danger" onClick={() => handleDeleteCustomer(deleteId)}>Delete</Button>
                </Modal.Footer>
            </Modal>
            {/* Modal ToDo List */}
            <Modal show={showTodoModal} onHide={() => setShowTodoModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>ToDo List</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table>
                        <thead>
                            <tr>
                                <th>Title</th>
                                <th>Due Date</th>
                                <th>Notes</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {todos && todos.length > 0 ? (
                                todos.map((todo) => (
                                    <tr key={todo._id}>
                                        <td>{todo.title}</td>
                                        <td>{todo.dueDate ? new Date(todo.dueDate).toLocaleDateString() : 'N/A'}</td>
                                        <td>{todo.notes}</td>
                                        <td>
                                            <Button variant="warning" onClick={() => setEditingTodo(todo)}>Edit</Button>
                                            <Button variant="danger" onClick={() => handleDeleteTodo(todo._id)}>Delete</Button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="4" className="text-center">No ToDo items found.</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type="text"
                                value={newTodo.title}
                                onChange={(e) => setNewTodo({ ...newTodo, title: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Due Date</Form.Label>
                            <Form.Control
                                type="date"
                                value={newTodo.dueDate}
                                onChange={(e) => setNewTodo({ ...newTodo, dueDate: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Notes</Form.Label>
                            <Form.Control
                                type="text"
                                value={newTodo.notes}
                                onChange={(e) => setNewTodo({ ...newTodo, notes: e.target.value })}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowTodoModal(false)}>Close</Button>
                    <Button variant="primary" onClick={handleSaveTodo}>
                        {editingTodo ? 'Save Changes' : 'Add ToDo'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default CustomerListPage;
