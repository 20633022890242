import React, { useState, useEffect } from 'react';
import axios from './../../../api/api';
import { Button, Form, Table, Container, Modal } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import CSS của React Quill

// ConfigListPage component
const ConfigListPage = () => {
    const [configs, setConfigs] = useState([]);
    const [newConfig, setNewConfig] = useState({
        configKey: '',
        configValueType: 'text',
        configValue: '',
    });
    const [editingConfig, setEditingConfig] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [deleteId, setDeleteId] = useState(null);

    useEffect(() => {
        fetchConfigs();
    }, []);

    const fetchConfigs = async () => {
        try {
            const response = await axios.get('/configurations');
            setConfigs(response.data);
        } catch (err) {
            console.error('Error fetching configs:', err);
        }
    };

    // Save or update config
    const handleSaveConfig = async () => {
        try {
            if (editingConfig && editingConfig._id) {
                await axios.put(`/configurations/${editingConfig._id}`, editingConfig);
            } else {
                if (newConfig.configKey === '' || newConfig.configValue === '') {
                    alert('ConfigKey và ConfigValue là bắt buộc!');
                    return;
                }
                await axios.post('/configurations', newConfig);
                setNewConfig({
                    configKey: '',
                    configValueType: 'text',
                    configValue: '',
                });
            }
            setShowModal(false);
            fetchConfigs();
        } catch (error) {
            console.error("Error saving config:", error);
        }
    };

    // Delete config
    const handleDeleteConfig = async (id) => {
        try {
            await axios.delete(`/configurations/${id}`);
            fetchConfigs();
        } catch (error) {
            console.error("Error deleting config:", error);
        }
    };

    // Handle input change for configValueType and adjust configValue accordingly
    const handleConfigValueTypeChange = (value) => {
        if (editingConfig) {
            setEditingConfig({ ...editingConfig, configValueType: value, configValue: '' });
        } else {
            setNewConfig({ ...newConfig, configValueType: value, configValue: '' });
        }
    };

    // Hàm render control cho configValue
    const renderConfigValueInput = (configValueType, configValue, handleChange) => {
        switch (configValueType) {
            case 'text':
                return (
                    <Form.Control
                        type="text"
                        value={configValue}
                        onChange={handleChange}
                    />
                );
            case 'html':
                return (
                    <ReactQuill
                        value={configValue}
                        onChange={(content) => handleChange({ target: { value: content } })}
                        theme="snow" // Giao diện của trình soạn thảo
                    />
                );
            case 'image':
                return (
                    <Form.Control
                        type="file"
                        onChange={(e) => handleChange(e, 'file')}
                    />
                );
            case 'youtubelink':
                return (
                    <Form.Control
                        type="url"
                        value={configValue}
                        onChange={handleChange}
                    />
                );
            default:
                return null;
        }
    };

    /*const openModal = (config = null) => {
        setEditingConfig(config || {
            configKey: '',
            configValueType: 'text',
            configValue: '',
        });
        setNewConfig(config || {
            configKey: '',
            configValueType: 'text',
            configValue: '',
        });
        setShowModal(true);
    };
    */
    const openModal = (config = null) => {
        if (config) {
            setEditingConfig(config);
        } else {
            setNewConfig({
                configKey: '',
                configValueType: 'text',
                configValue: '',
            });
            setEditingConfig(null); // Đảm bảo không chỉnh sửa
        }
        setShowModal(true);
    };
    
    const closeModal = () => {
        setEditingConfig(null);
        setShowModal(false);
    };

    return (
        <div>
            <h2 className="my-4">Config Management</h2>

            <Button variant="primary" className="mb-4" onClick={() => openModal()}>
                Add New Config
            </Button>

            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Config Key</th>
                        <th>Config Value Type</th>
                        <th>Config Value</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {configs.map((config) => (
                        <tr key={config._id}>
                            <td>{config.configKey}</td>
                            <td>{config.configValueType}</td>
                            <td>{config.configValueType === 'image' ? <img src={config.configValue} alt="config" width="100" /> : config.configValue}</td>
                            <td>
                                <Button variant="warning" className="me-2" onClick={() => openModal(config)}>
                                    Edit
                                </Button>
                                <Button variant="danger" onClick={() => handleDeleteConfig(config._id)}>
                                    Delete
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {/* Modal for creating/editing config */}
            <Modal show={showModal} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{editingConfig ? 'Edit Config' : 'Add New Config'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Config Key</Form.Label>
                            <Form.Control
                                type="text"
                                value={editingConfig ? editingConfig.configKey : newConfig.configKey}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (editingConfig) {
                                        setEditingConfig({ ...editingConfig, configKey: value });
                                    } else {
                                        setNewConfig({ ...newConfig, configKey: value });
                                    }
                                }}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Config Value Type</Form.Label>
                            <Form.Control
                                as="select"
                                value={editingConfig ? editingConfig.configValueType : newConfig.configValueType}
                                onChange={(e) => handleConfigValueTypeChange(e.target.value)}
                            >
                                <option value="text">Text</option>
                                <option value="html">HTML</option>
                                <option value="image">Image</option>
                                <option value="youtubelink">YouTube Link</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Config Value</Form.Label>
                            {renderConfigValueInput(
                                editingConfig ? editingConfig.configValueType : newConfig.configValueType,
                                editingConfig ? editingConfig.configValue : newConfig.configValue,
                                (e, type = 'text') => {
                                    if (type === 'file') {
                                        const file = e.target.files[0];
                                        const formData = new FormData();
                                        formData.append('image', file);
                                        axios.post('/upload', formData, {
                                            headers: {
                                                'Content-Type': 'multipart/form-data',
                                            },
                                        })
                                            .then((res) => {
                                                const fileUrl = res.data.url;
                                                if (editingConfig) {
                                                    setEditingConfig({ ...editingConfig, configValue: fileUrl });
                                                } else {
                                                    setNewConfig({ ...newConfig, configValue: fileUrl });
                                                }
                                            })
                                            .catch((err) => {
                                                console.error('Error uploading file:', err);
                                            });
                                    } else {
                                        const value = e.target.value;
                                        if (editingConfig) {
                                            setEditingConfig({ ...editingConfig, configValue: value });
                                        } else {
                                            setNewConfig({ ...newConfig, configValue: value });
                                        }
                                    }
                                }
                            )}
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>Close</Button>
                    <Button variant="primary" onClick={handleSaveConfig}>
                        {editingConfig ? 'Save Changes' : 'Add Config'}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Delete confirmation modal */}
            <Modal show={!!deleteId} onHide={() => setDeleteId(null)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this config?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setDeleteId(null)}>Cancel</Button>
                    <Button variant="danger" onClick={() => handleDeleteConfig(deleteId)}>Delete</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ConfigListPage;
