import React, { useState, useEffect } from 'react';
import api from '../api/api'; // Sử dụng api từ api.js
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap

function ClubRegistrationForm() {
    const [formData, setFormData] = useState({
        sportId: '',
        clubName: '',
        avatar: null,  // Avatar file
        location: '',
        operatingHours: '',
        currentPresidentName: '',
        managerName: '',
        managerTitle: '',
        managerEmail: '',
        managerPhoneNumber: '',
        foundedDate: ''
    });

    const [sports, setSports] = useState([]); // State để lưu danh sách môn thể thao
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    // Lấy danh sách môn thể thao từ API khi component được mount
    useEffect(() => {
        const fetchSports = async () => {
            try {
                const response = await api.get('/sports'); // Gọi API lấy danh sách môn thể thao
                setSports(response.data); // Lưu danh sách môn thể thao vào state
            } catch (error) {
                console.error('Lỗi khi tải danh sách môn thể thao', error);
            }
        };

        fetchSports();
    }, []); // Chạy một lần khi component được mount

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleFileChange = (e) => {
        setFormData(prevState => ({
            ...prevState,
            avatar: e.target.files[0]  // Lưu avatar file vào state
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
    
        try {
            // Bước 1: Upload avatar nếu có
            let avatarUrl = '';
            if (formData.avatar) {
                const avatarData = new FormData();
                avatarData.append('avatar', formData.avatar);
                const uploadResponse = await api.post('/upload', avatarData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                avatarUrl = uploadResponse.data.url;
            }
    
            // Bước 2: Gửi form đăng ký với avatar URL
            const formDataToSend = {
                sportId: formData.sportId,
                clubName: formData.clubName,
                avatar: avatarUrl,  // URL của avatar nếu có
                location: formData.location,
                operatingHours: formData.operatingHours,
                currentPresidentName: formData.currentPresidentName,
                managerName: formData.managerName,
                managerTitle: formData.managerTitle,
                managerEmail: formData.managerEmail,
                managerPhoneNumber: formData.managerPhoneNumber,
                foundedDate: formData.foundedDate
            };
    
            const response = await api.post('/club-registrations', formDataToSend); // Đăng ký câu lạc bộ
            setMessage('Đăng ký thành công, vùi lòng kiểm tra email để nhận tài khoản!');
    
            // Reset form về trạng thái ban đầu
            setFormData({
                sportId: '',
                clubName: '',
                avatar: null,
                location: '',
                operatingHours: '',
                currentPresidentName: '',
                managerName: '',
                managerTitle: '',
                managerEmail: '',
                managerPhoneNumber: '',
                foundedDate: ''
            });
        } catch (error) {
            setMessage('Có lỗi xảy ra khi đăng ký, vui lòng thử lại.');
        } finally {
            setLoading(false);
        }
    };
    

    return (
        <div className="container mt-5">
            <h2>Đăng ký thành lập câu lạc bộ</h2>
            {message && <div className="alert alert-info">{message}</div>}
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label className="form-label">Môn thể thao:</label>
                    <select 
                        className="form-select" 
                        name="sportId" 
                        value={formData.sportId} 
                        onChange={handleChange} 
                        required>
                        <option value="">Chọn môn thể thao</option>
                        {sports.map((sport) => (
                            <option key={sport._id} value={sport._id}>
                                {sport.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="mb-3">
                    <label className="form-label">Tên CLB:</label>
                    <input type="text" className="form-control" name="clubName" value={formData.clubName} onChange={handleChange} required />
                </div>
                <div className="mb-3">
                    <label className="form-label">Ảnh đại diện (không quá 1MB):</label>
                    <input type="file" className="form-control" name="avatar" onChange={handleFileChange} />
                </div>
                <div className="mb-3">
                    <label className="form-label">Địa điểm sinh hoạt:</label>
                    <input type="text" className="form-control" name="location" value={formData.location} onChange={handleChange} />
                </div>
                <div className="mb-3">
                    <label className="form-label">Giờ sinh hoạt:</label>
                    <input type="text" className="form-control" name="operatingHours" value={formData.operatingHours} onChange={handleChange} />
                </div>
                <div className="mb-3">
                    <label className="form-label">Họ tên chủ tịch đương nhiệm (nếu có):</label>
                    <input type="text" className="form-control" name="currentPresidentName" value={formData.currentPresidentName} onChange={handleChange} />
                </div>
                <div className="mb-3">
                    <label className="form-label">Họ tên người đăng ký:</label>
                    <input type="text" className="form-control" name="managerName" value={formData.managerName} onChange={handleChange} required />
                </div>
                <div className="mb-3">
                    <label className="form-label">Chức vụ người đăng ký:</label>
                    <input type="text" className="form-control" name="managerTitle" value={formData.managerTitle} onChange={handleChange} required />
                </div>
                <div className="mb-3">
                    <label className="form-label">Email người đăng ký:</label>
                    <input type="email" className="form-control" name="managerEmail" value={formData.managerEmail} onChange={handleChange} required />
                </div>
                <div className="mb-3">
                    <label className="form-label">Số điện thoại người đăng ký:</label>
                    <input type="text" className="form-control" name="managerPhoneNumber" value={formData.managerPhoneNumber} onChange={handleChange} required />
                </div>
                <div className="mb-3">
                    <label className="form-label">Ngày thành lập CLB:</label>
                    <input type="date" className="form-control" name="foundedDate" value={formData.foundedDate} onChange={handleChange} />
                </div>
                <button type="submit" className="btn btn-primary" disabled={loading}>
                    {loading ? 'Đang xử lý...' : 'Đăng ký'}
                </button>
            </form>
        </div>
    );
}

export default ClubRegistrationForm;
