import React, { useState, useEffect } from 'react';
import axios from './../../../api/api';
import './MyClubPage.css';

const MyClubPage = () => {
    const [clubs, setClubs] = useState([]);
    const [clubCode, setClubCode] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        const fetchClubs = async () => {
            try {
                const response = await axios.get('/club-members/my-clubs');
                setClubs(response.data);
            } catch (err) {
                console.error('Error fetching clubs:', err);
            }
        };

        fetchClubs();
    }, []);

    const handleJoinClub = async () => {
        try {
            const response = await axios.post('/club-members/join-club', { clubCode });
            setMessage(response.data.message);
        } catch (err) {
            console.error('Error joining club:', err);
            setMessage('There was an error.');
        }
    };

    return (
        <div>
            <h1>Các Câu Lạc Bộ của Bạn</h1>
            <div className="myclub-list">
                {clubs.map((club) => (
                    <div className="myclub-card" key={club._id}>
                        <h2 className="myclub-name">CÂU LẠC BỘ {club.clubId.name}</h2>
                        <h2 className="myclub-card-title">THẺ HỘI VIÊN</h2>
                        <h2 className="myclub-card-title">{club.userId.fullName}</h2>

                        {/* Thêm ảnh đại diện */}
                        <img
                            className="myclub-avatar"
                            src={`${process.env.REACT_APP_API_BASE_URL}/${club.userId.avatar}`}
                            alt="Avatar"
                            width="100"
                        />

                        <p><span className="myclub-label">Địa chỉ:</span> <span className="myclub-info">{club.clubId.location}</span></p>
                        <p><span className="myclub-label">Ghi chú:</span> <span className="myclub-info">{club.clubId.notes}</span></p>

                        <p><span className="myclub-label">Email hội viên:</span> <span className="myclub-info">{club.userId.email}</span></p>
                        <p><span className="myclub-label">Số điện thoại hội viên:</span> <span className="myclub-info">{club.userId.phoneNumber}</span></p>
                        <p><span className="myclub-label">Tình trạng:</span> <span className="myclub-info">{club.status}</span></p>
                    </div>
                ))}
            </div>

            <div className="myclub-join-club">
                <h2>Xin tham gia Câu Lạc Bộ mới</h2>
                <input
                    type="text"
                    placeholder="Nhập mã câu lạc bộ"
                    value={clubCode}
                    onChange={(e) => setClubCode(e.target.value)}
                />
                <button onClick={handleJoinClub}>Xin tham gia CLB</button>
                {message && <p>{message}</p>}
            </div>
        </div>
    );
};

export default MyClubPage;
