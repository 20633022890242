import React, { useState, useEffect } from 'react';
import axios from './../../api/api';
import './MatchesPage.css';
import { Container } from 'react-bootstrap';

const MatchesPage = () => {
    const [matches, setMatches] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchMatches(currentPage);
    }, [currentPage]);

    const fetchMatches = async (page) => {
        setLoading(true);
        try {
            const response = await axios.get(`/matches/matchesbyday?page=${page}&limit=40`);
            setMatches(response.data.matches);
            setCurrentPage(response.data.currentPage);
            setTotalPages(response.data.totalPages);
        } catch (err) {
            console.error('Error fetching matches:', err);
        } finally {
            setLoading(false);
        }
    };

    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        return new Date(dateString).toLocaleDateString('vi-VN', options);
    };

    const groupMatchesByDate = (matches) => {
        const groupedMatches = {};
        matches.forEach((match) => {
            const date = formatDate(match.date);
            if (!groupedMatches[date]) {
                groupedMatches[date] = [];
            }
            groupedMatches[date].push(match);
        });
        return groupedMatches;
    };

    const renderMatches = () => {
        const groupedMatches = groupMatchesByDate(matches);

        return Object.keys(groupedMatches).map((date) => (
            <div key={date}>
                <h3>Ngày {date}:</h3>
                <div className="matches-row">
                    {groupedMatches[date].map((match, index) => (
                        <div key={match._id} className={`match-card ${index % 2 === 0 ? 'dark-background' : 'light-background'}`}>
                            {/* Dòng 1 - Ảnh và tên vận động viên 1 của đội 1, tên và ảnh của đội 2 */}
                            <div className="row">
                                <div className="col left">
                                    <img
                                        src={`${process.env.REACT_APP_API_BASE_URL}/${match.team1[0]?.userId?.avatar}`}
                                        alt="Avatar 1"
                                        className="team-avatar"
                                    />
                                    <p>{match.team1[0]?.userId?.fullName}</p>
                                </div>
                                <div className="col right">
                                    <p>{match.team2[0]?.userId?.fullName}</p>
                                    <img
                                        src={`${process.env.REACT_APP_API_BASE_URL}/${match.team2[0]?.userId?.avatar}`}
                                        alt="Avatar 1"
                                        className="team-avatar"
                                    />
                                </div>
                            </div>

                            {/* Dòng 2 nếu có vận động viên 2 */}
                            {(match.team1[1] || match.team2[1]) && (
                                <div className="row">
                                    <div className="col left">
                                        {match.team1[1] && (
                                            <>
                                                <img
                                                    src={`${process.env.REACT_APP_API_BASE_URL}/${match.team1[1]?.userId?.avatar}`}
                                                    alt="Avatar 2"
                                                    className="team-avatar"
                                                />
                                                <p>{match.team1[1]?.userId?.fullName}</p>
                                            </>
                                        )}
                                    </div>
                                    <div className="col right">
                                        {match.team2[1] && (
                                            <>
                                                <p>{match.team2[1]?.userId?.fullName}</p>
                                                <img
                                                    src={`${process.env.REACT_APP_API_BASE_URL}/${match.team2[1]?.userId?.avatar}`}
                                                    alt="Avatar 2"
                                                    className="team-avatar"
                                                />
                                            </>
                                        )}
                                    </div>
                                </div>
                            )}

                            {/* Dòng kết quả */}
                            <div className="row result-row">
                                <div className="col center">
                                    <span className="team-score">{match.finalScore.team1Total}</span>
                                </div>
                                <div className="col center">
                                    <span className="team-score">{match.finalScore.team2Total}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        ));
    };

    return (
        <Container>
            <div>
                <h1>Kết Quả Các Trận Đấu</h1>
                {loading ? (
                    <p>Đang tải...</p>
                ) : (
                    <div>{renderMatches()}</div>
                )}

                <div className="pagination">
                    <button
                        disabled={currentPage === 1}
                        onClick={() => setCurrentPage(currentPage - 1)}
                    >
                        Trang trước
                    </button>
                    <span>Trang {currentPage} / {totalPages}</span>
                    <button
                        disabled={currentPage === totalPages}
                        onClick={() => setCurrentPage(currentPage + 1)}
                    >
                        Trang sau
                    </button>
                </div>
            </div>
        </Container>
    );
};

export default MatchesPage;
