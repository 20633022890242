export function getMenuItems(roles) {
  console.log('getMenuItems: ', roles);

  // Đảm bảo rằng roles là một mảng, nếu không phải thì chuyển thành mảng rỗng
  if (!Array.isArray(roles)) {
    roles = [roles]; // Nếu roles là chuỗi đơn lẻ, chuyển nó thành mảng
  }

  const menuItems = {
    "guest": [
      { name: 'Trang chủ', path: '/' },
      { name: 'Kết quả giao lưu', path: '/matches' },
      { name: 'Câu lạc bộ', path: '/clubs' },
      { name: 'Huấn luyện viên', path: '/coaches' },
      { name: 'Cụm sân', path: '/stadiums' },
      { name: 'Videos', path: '/videos' },
      { name: 'Trọng tài', path: '/referees' },
      { name: 'Giải đấu', path: '/programs' },
      { name: 'Liên hệ', path: '/contact' },
      { name: 'Đăng ký CLB', path: '/club-register' },
      { name: 'Đăng ký chủ sân', path: '/stadium-register' },
      { name: 'Đăng ký người chơi', path: '/member-register' },
      { name: 'Đăng nhập', path: '/login' },
    ],     
    "cus":[
      { name: 'Chăm sóc khách hàng', path: '../customers' },

    ],
    "user": [      
      { name: 'Hồ sơ người dùng', path: '../my-profile' },
      { name: 'Câu lạc bộ của tôi', path: '../my-clubs' },
      { name: 'Đổi mật khẩu', path: '../change-password' },
    ],
    "manager": [
      { name: 'Quản lý CLB', path: '../manage-clubs' },
      { name: 'Quản lý thành viên', path: '../club-members' },
      { name: 'Quản lý buổi tập luyện', path: '../club-practices' },
      { name: 'Quản lý sân cho thuê', path: '../playgrouds' },
      { name: 'Quản lý giờ cho thuê', path: '../slots' },
    ],
    "admin": [
      { name: 'Dashboard', path: '../dashboard' },
      { name: 'Các cấu hình', path: '../configurations' },
      { name: 'User Management', path: '../users-management' },
      { name: 'Các môn thể thao', path: '../sports' },
      { name: 'Các bài viết', path: '../articles' },
      { name: 'Các huấn luyện viên', path: '../coaches' },
      { name: 'Các câu lạc bộ', path: '../clubs' },
      { name: 'Các trọng tài', path: '../referees' },
      { name: 'Các videos', path: '../videos' },
      { name: 'Quản lý đăng ký', path: '../club-registration' },
      { name: 'Quản lý giải đấu', path: '../tournaments' },
      { name: 'Quản lý cụm sân', path: '../stadiums' },
      { name: 'Quản lý khách hàng', path: '../customers' },
    ],
  };

  const combinedItems = [];

  roles.forEach(role => {
    if (menuItems[role]) {
      menuItems[role].forEach(item => {
        if (!combinedItems.some(existingItem => existingItem.name === item.name && existingItem.path === item.path)) {
          combinedItems.push(item);
        }
      });
    }
  });

  return combinedItems;
}
