import React, { useState, useEffect } from 'react';
import axios from './../../../api/api';
import { Table, Button, Container, Form, Modal } from 'react-bootstrap';

const SlotManagement = () => {
    const [fromDate, setFromDate] = useState(new Date().toISOString().split('T')[0]);
    const [toDate, setToDate] = useState(new Date(new Date().setDate(new Date().getDate() + 2)).toISOString().split('T')[0]);
    const [slotData, setSlotData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedPlayground, setSelectedPlayground] = useState(null);
    const [slots, setSlots] = useState([]);
    const [showSlotModal, setShowSlotModal] = useState(false);
    const [showCopyModal, setShowCopyModal] = useState(false); // Modal trạng thái copy
    const [copyDateRange, setCopyDateRange] = useState({ fromDate: '', toDate: '' });
    const [newSlot, setNewSlot] = useState({
        fromHour: '',
        fromMinute: '',
        toHour: '',
        toMinute: '',
        status: 'rỗi',
        rentalPricePerHour: '',
        notes: '',
        slotId: null // thêm slotId để theo dõi slot đang sửa
    });

    const fetchSlotData = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get('/timeslots', {
                params: {
                    fromDate,
                    toDate,
                },
            });
            setSlotData(response.data);
        } catch (err) {
            console.error('Error fetching slot data:', err);
            setError('Có lỗi xảy ra khi tải dữ liệu');
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchSlotData();
    }, [fromDate, toDate]);

    const fetchSlotsForPlayground = async (playgroundId, date) => {
        try {
            const response = await axios.get(`/timeslots/${playgroundId}/${date}`);
            setSlots(response.data);
        } catch (err) {
            console.error('Error fetching playground slots:', err);
        }
    };

    const handleShowSlots = (playgroundId, date) => {
        setSelectedPlayground({ playgroundId, date });
        fetchSlotsForPlayground(playgroundId, date);
        setShowSlotModal(true);
    };

    const handleSaveSlot = async () => {
        try {
            if (newSlot.slotId) {
                // Cập nhật slot nếu đã có slotId
                await axios.put(`/timeslots/${newSlot.slotId}`, { ...newSlot });
            } else {
                // Thêm mới slot nếu chưa có slotId
                await axios.post('/timeslots', { ...newSlot, playgroundId: selectedPlayground.playgroundId, date: selectedPlayground.date });
            }
            fetchSlotsForPlayground(selectedPlayground.playgroundId, selectedPlayground.date);
            setNewSlot({ fromHour: '', fromMinute: '', toHour: '', toMinute: '', status: 'rỗi', rentalPricePerHour: '', notes: '', slotId: null });
        } catch (err) {
            console.error('Error saving slot:', err);
        }
    };

    const handleDeleteSlot = async (slotId) => {
        try {
            await axios.delete(`/timeslots/${slotId}`);
            fetchSlotsForPlayground(selectedPlayground.playgroundId, selectedPlayground.date);
        } catch (err) {
            console.error('Error deleting slot:', err);
        }
    };

    const handleEditSlot = (slot) => {
        // Đặt giá trị slot đang chỉnh sửa vào newSlot
        setNewSlot({
            fromHour: slot.fromHour,
            fromMinute: slot.fromMinute,
            toHour: slot.toHour,
            toMinute: slot.toMinute,
            status: slot.status,
            rentalPricePerHour: slot.rentalPricePerHour,
            notes: slot.notes,
            slotId: slot._id, // Lưu slotId để biết đây là slot cần chỉnh sửa
        });
    };

    // Xử lý sao chép slot
    const handleCopySlots = async () => {
        try {
            await axios.post('/timeslots/copy', {
                playgroundId: selectedPlayground.playgroundId,
                fromDate: selectedPlayground.date,
                toDateRange: copyDateRange
            });
            setShowCopyModal(false);
            fetchSlotData();
        } catch (err) {
            console.error('Error copying slots:', err);
        }
    };

    return (
        <div>
            <h2 className="my-4">Quản lý Slot</h2>

            <Form className="d-flex justify-content-between mb-4">
                <Form.Group controlId="fromDate">
                    <Form.Label>Từ ngày</Form.Label>
                    <Form.Control
                        type="date"
                        value={fromDate}
                        onChange={(e) => setFromDate(e.target.value)}
                    />
                </Form.Group>
                <Form.Group controlId="toDate">
                    <Form.Label>Đến ngày</Form.Label>
                    <Form.Control
                        type="date"
                        value={toDate}
                        onChange={(e) => setToDate(e.target.value)}
                    />
                </Form.Group>
                <Button variant="primary" onClick={fetchSlotData} className="align-self-end">
                    Lấy dữ liệu
                </Button>
            </Form>

            {loading ? (
                <p>Đang tải dữ liệu...</p>
            ) : error ? (
                <p className="text-danger">{error}</p>
            ) : (
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>BQL Sân</th>
                            <th>Sân</th>
                            <th>Ngày</th>
                            <th>Tổng số ca chơi</th>
                            <th>Số ca bận</th>
                            <th>Số ca rỗi</th>
                            <th>Hành động</th>
                        </tr>
                    </thead>
                    <tbody>
                        {slotData.length > 0 ? (
                            slotData.map((slot, index) => (
                                <tr key={index}>
                                    <td>{slot.stadiumName}</td>
                                    <td>{slot.playgroundName}</td>
                                    <td>{slot.date}</td>
                                    <td>{slot.totalSlots}</td>
                                    <td>{slot.busySlots}</td>
                                    <td>{slot.freeSlots}</td>
                                    <td>
                                        <Button variant="info" onClick={() => handleShowSlots(slot.playgroundId, slot.date)}>
                                            Chi tiết
                                        </Button>
                                        <Button variant="secondary" onClick={() => {
                                            setSelectedPlayground({ playgroundId: slot.playgroundId, date: slot.date });
                                            setShowCopyModal(true);
                                        }}>
                                            Copy To
                                        </Button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="7">Không có dữ liệu</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            )}

            {/* Modal sao chép slot */}
            <Modal show={showCopyModal} onHide={() => setShowCopyModal(false)}>
                <Modal.Header closeButton><Modal.Title>Copy Slots</Modal.Title></Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Từ ngày</Form.Label>
                        <Form.Control
                            type="date"
                            value={copyDateRange.fromDate}
                            onChange={(e) => setCopyDateRange({ ...copyDateRange, fromDate: e.target.value })}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Đến ngày</Form.Label>
                        <Form.Control
                            type="date"
                            value={copyDateRange.toDate}
                            onChange={(e) => setCopyDateRange({ ...copyDateRange, toDate: e.target.value })}
                        />
                    </Form.Group>
                    <Button variant="primary" onClick={handleCopySlots}>Copy</Button>
                </Modal.Body>
            </Modal>

            {/* Modal chi tiết slot */}
            <Modal show={showSlotModal} onHide={() => setShowSlotModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Chi tiết Slot</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Từ giờ</th>
                                <th>Đến giờ</th>
                                <th>Trạng thái</th>
                                <th>Giá thuê (giờ)</th>
                                <th>Ghi chú</th>
                                <th>Hành động</th>
                            </tr>
                        </thead>
                        <tbody>
                            {slots.map((slot) => (
                                <tr key={slot._id}>
                                    <td>{`${slot.fromHour}:${slot.fromMinute}`}</td>
                                    <td>{`${slot.toHour}:${slot.toMinute}`}</td>
                                    <td>{slot.status}</td>
                                    <td>{slot.rentalPricePerHour}</td>
                                    <td>{slot.notes}</td>
                                    <td>
                                        <Button variant="warning" onClick={() => handleEditSlot(slot)}>
                                            Sửa
                                        </Button>
                                        <Button variant="danger" onClick={() => handleDeleteSlot(slot._id)}>
                                            Xóa
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>

                    <Form>
                        <Form.Group>
                            <Form.Label>Từ giờ</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Giờ"
                                value={newSlot.fromHour}
                                onChange={(e) => setNewSlot({ ...newSlot, fromHour: e.target.value })}
                            />
                            <Form.Control
                                type="number"
                                placeholder="Phút"
                                value={newSlot.fromMinute}
                                onChange={(e) => setNewSlot({ ...newSlot, fromMinute: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Đến giờ</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Giờ"
                                value={newSlot.toHour}
                                onChange={(e) => setNewSlot({ ...newSlot, toHour: e.target.value })}
                            />
                            <Form.Control
                                type="number"
                                placeholder="Phút"
                                value={newSlot.toMinute}
                                onChange={(e) => setNewSlot({ ...newSlot, toMinute: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Trạng thái</Form.Label>
                            <Form.Control
                                as="select"
                                value={newSlot.status}
                                onChange={(e) => setNewSlot({ ...newSlot, status: e.target.value })}
                            >
                                <option value="rỗi">Rỗi</option>
                                <option value="bận">Bận</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Giá thuê (giờ)</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Giá"
                                value={newSlot.rentalPricePerHour}
                                onChange={(e) => setNewSlot({ ...newSlot, rentalPricePerHour: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Ghi chú</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Ghi chú"
                                value={newSlot.notes}
                                onChange={(e) => setNewSlot({ ...newSlot, notes: e.target.value })}
                            />
                        </Form.Group>
                        <Button variant="primary" onClick={handleSaveSlot}>
                            {newSlot.slotId ? 'Lưu thay đổi' : 'Thêm Slot'}
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default SlotManagement;
