import React, { useState, useEffect } from 'react';
import { Form, Button, Alert, Table } from 'react-bootstrap';
import api from '../../../api/api';  // API helper

const ClubManagement = () => {
    const [clubs, setClubs] = useState([]);
    const [selectedClub, setSelectedClub] = useState('');
    const [clubDetails, setClubDetails] = useState(null);
    const [sports, setSports] = useState([]);
    const [managers, setManagers] = useState([]);  // List of club managers
    const [inviteEmail, setInviteEmail] = useState('');  // Email to invite manager
    const [inviteError, setInviteError] = useState('');
    const [inviteSuccess, setInviteSuccess] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    // Lấy danh sách các CLB và môn thể thao
    useEffect(() => {
        const fetchClubsAndSports = async () => {
            try {
                const [clubsResponse, sportsResponse] = await Promise.all([
                    api.get('/clubs/club-of-users'),
                    api.get('/sports')
                ]);
    
                setClubs(clubsResponse.data.clubs || []);
                setSports(sportsResponse.data || []);
    
                if (clubsResponse.data.clubs && clubsResponse.data.clubs.length > 0) {
                    const firstClubId = clubsResponse.data.clubs[0].clubId._id;
                    setSelectedClub(firstClubId);
                    fetchClubDetails(firstClubId);
                    fetchClubManagers(firstClubId);  // Load managers for the first club
                }
            } catch (err) {
                setError('Lỗi khi tải dữ liệu CLB hoặc môn thể thao.');
            } finally {
                setLoading(false);
            }
        };
    
        fetchClubsAndSports();
    }, []);

    // Lấy chi tiết CLB theo ID
    const fetchClubDetails = async (clubId) => {
        try {
            const response = await api.get(`/clubs/details/${clubId}`);
            setClubDetails(response.data);  // Lưu chi tiết CLB
        } catch (err) {
            setError('Lỗi khi tải dữ liệu chi tiết CLB.');
        }
    };

    const fetchClubManagers = async (clubId) => {
        try {
            const response = await api.get(`/clubs/${clubId}/managers`);
            setManagers(response.data);  // Store the list of managers
        } catch (err) {
            setError('Lỗi khi tải danh sách người quản lý.');
        }
    };
    // Xử lý khi thay đổi lựa chọn CLB
    const handleClubChange = (event) => {
        const clubId = event.target.value;
        setSelectedClub(clubId);
        fetchClubDetails(clubId);
        fetchClubManagers(clubId);  // Update the managers list when club changes
    };

    const handleInviteSubmit = async (event) => {
        event.preventDefault();
        setInviteError('');
        setInviteSuccess('');

        try {
            const response = await api.post(`/clubs/${selectedClub}/invite-manager`, { email: inviteEmail });
            setInviteSuccess(response.data.message);
            fetchClubManagers(selectedClub);  // Refresh the list of managers
        } catch (err) {
            setInviteError(err.response?.data?.message || 'Lỗi khi mời quản lý mới.');
        }
    };

    // Xử lý khi người dùng nhấn nút cập nhật
    const handleSubmit = async (event) => {
        event.preventDefault();
        setUpdating(true);  // Bắt đầu quá trình cập nhật

        const formData = new FormData();
        formData.append('sportId', event.target.sportId.value);
        formData.append('name', event.target.name.value);
        formData.append('joinCode', event.target.joinCode.value);
        formData.append('uniqueUrl', event.target.uniqueUrl.value);
        formData.append('foundedDate', event.target.foundedDate.value);
        formData.append('operatingHours', event.target.operatingHours.value);
        formData.append('location', event.target.location.value);
        formData.append('president', event.target.president.value);
        formData.append('introduction', event.target.introduction.value);
        formData.append('notes', event.target.notes.value);

        // Nếu người dùng chọn ảnh mới, thêm vào formData
        if (event.target.avatar.files[0]) {
            formData.append('avatar', event.target.avatar.files[0]);
        }

        try {
            // Gửi yêu cầu cập nhật CLB
            await api.put(`/clubs/${selectedClub}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            alert('Cập nhật thành công!');
            fetchClubDetails(selectedClub);  // Cập nhật lại chi tiết sau khi thành công
        } catch (err) {
            console.error('Error updating club:', err);
            setError('Lỗi khi cập nhật thông tin CLB.');
        } finally {
            setUpdating(false);  // Kết thúc quá trình cập nhật
        }
    };

    if (loading) {
        return <div>Loading...</div>;  // Hiển thị trong lúc chờ dữ liệu tải
    }

    return (
        <div className="club-management">
            <h2>Câu lạc bộ của tôi</h2>

            {error && <Alert variant="danger">{error}</Alert>}

            {Array.isArray(clubs) && clubs.length > 0 ? (
                <Form.Group controlId="clubSelect">
                    <Form.Label>Chọn Câu Lạc Bộ</Form.Label>
                    <Form.Control as="select" value={selectedClub} onChange={handleClubChange}>
                        {clubs.map(club => (
                            <option key={club.clubId._id} value={club.clubId._id}>
                                {club.clubId.name}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>
            ) : (
                <p>Bạn không quản lý câu lạc bộ nào.</p>
            )}

            {clubDetails && (
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="sportId">
                        <Form.Label>Môn thể thao</Form.Label>
                        <Form.Control as="select" name="sportId" defaultValue={clubDetails?.sportId || ''}>
                            {sports.map(sport => (
                                <option key={sport._id} value={sport._id}>
                                    {sport.name}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>

                    <Form.Group controlId="name">
                        <Form.Label>Tên Câu Lạc Bộ</Form.Label>
                        <Form.Control type="text" name="name" defaultValue={clubDetails.name} required />
                    </Form.Group>

                    <Form.Group controlId="joinCode">
                        <Form.Label>Mã tham gia</Form.Label>
                        <Form.Control type="text" name="joinCode" defaultValue={clubDetails.joinCode} />
                    </Form.Group>

                    <Form.Group controlId="uniqueUrl">
                        <Form.Label>Đường dẫn duy nhất</Form.Label>
                        <Form.Control type="text" name="uniqueUrl" defaultValue={clubDetails.uniqueUrl} />
                    </Form.Group>

                    <Form.Group controlId="avatar">
                        <Form.Label>Ảnh đại diện</Form.Label>
                        <div>
                            <img src={`${process.env.REACT_APP_API_BASE_URL}/${clubDetails.avatar}`} alt="Club Avatar" style={{ width: '100px', height: '100px' }} />
                        </div>
                        <Form.Control type="file" name="avatar" />
                    </Form.Group>

                    <Form.Group controlId="foundedDate">
                        <Form.Label>Ngày thành lập</Form.Label>
                        <Form.Control type="date" name="foundedDate" defaultValue={new Date(clubDetails.foundedDate).toISOString().split('T')[0]} required />
                    </Form.Group>

                    <Form.Group controlId="operatingHours">
                        <Form.Label>Giờ hoạt động</Form.Label>
                        <Form.Control type="text" name="operatingHours" defaultValue={clubDetails.operatingHours} />
                    </Form.Group>

                    <Form.Group controlId="location">
                        <Form.Label>Địa điểm</Form.Label>
                        <Form.Control type="text" name="location" defaultValue={clubDetails.location} required />
                    </Form.Group>

                    <Form.Group controlId="president">
                        <Form.Label>Chủ nhiệm</Form.Label>
                        <Form.Control type="text" name="president" defaultValue={clubDetails.president} required />
                    </Form.Group>

                    <Form.Group controlId="introduction">
                        <Form.Label>Giới thiệu</Form.Label>
                        <Form.Control as="textarea" name="introduction" rows={3} defaultValue={clubDetails.introduction} />
                    </Form.Group>

                    <Form.Group controlId="notes">
                        <Form.Label>Ghi chú</Form.Label>
                        <Form.Control as="textarea" name="notes" rows={3} defaultValue={clubDetails.notes} />
                    </Form.Group>

                    <Button variant="primary" type="submit" disabled={updating}>
                        {updating ? 'Đang cập nhật...' : 'Cập nhật'}
                    </Button>
                </Form>
            )}
            <h3>Danh sách quản lý CLB</h3>
            {managers.length > 0 ? (
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Họ và Tên</th>
                            <th>Email</th>
                            <th>Điện thoại</th>
                            <th>Ảnh đại diện</th>
                        </tr>
                    </thead>
                    <tbody>
                        {managers.map(manager => (
                            <tr key={manager.userId._id}>
                                <td>{manager.userId.fullName}</td>
                                <td>{manager.userId.email}</td>
                                <td>{manager.userId.phoneNumber}</td>
                                <td><img src={`${process.env.REACT_APP_API_BASE_URL}/${manager.userId.avatar}`} alt="Avatar" width="50" /></td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            ) : (
                <p>Chưa có quản lý nào cho CLB này.</p>
            )}

            <h3>Mời quản lý mới</h3>
            {inviteError && <Alert variant="danger">{inviteError}</Alert>}
            {inviteSuccess && <Alert variant="success">{inviteSuccess}</Alert>}
            <Form onSubmit={handleInviteSubmit}>
                <Form.Group controlId="inviteEmail">
                    <Form.Label>Nhập email người dùng:</Form.Label>
                    <Form.Control
                        type="email"
                        value={inviteEmail}
                        onChange={(e) => setInviteEmail(e.target.value)}
                        required
                    />
                </Form.Group>
                <Button variant="primary" type="submit">Mời quản lý</Button>
            </Form>            
        </div>
        
    );
};

export default ClubManagement;
