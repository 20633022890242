import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getMenuItems } from '../Menu/Menuservice'; // Import hàm getMenuItems
import { useAuth } from '../../context/AuthContext'; // Import useAuth để lấy thông tin auth

const CommonMenu = () => {
  const [menuItems, setMenuItems] = useState([]);
  const { auth } = useAuth(); // Lấy thông tin auth từ context, trong đó có các roles của user

  useEffect(() => {
    if (auth && auth.roles) {
      const items = getMenuItems(auth.roles); // Truyền các roles của user vào hàm getMenuItems
      setMenuItems(items); // Cập nhật state với danh sách menu
    }
  }, [auth]); // Chỉ chạy khi auth thay đổi

  return (
    <nav style={{backgroundColor: '#f8f9fa', padding: '10px' }}>
      <ul style={{ listStyleType: 'none', padding: 0 }}>
        {menuItems.map((item, index) => (
          item.path && ( // Kiểm tra nếu path không rỗng
            <li key={index}>
              <Link to={item.path}>{item.name}</Link>
            </li>
          )
        ))}
      </ul>
    </nav>
  );
}

export default CommonMenu;
