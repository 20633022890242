/*import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';  // Import useAuth

const LoginPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const { setAuth } = useAuth();  // Dùng setAuth để lưu trạng thái đăng nhập
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();

        // Ví dụ đăng nhập thành công và nhận về token
        const fakeToken = 'some-fake-token';  // Đây là token giả định

        // Lưu trạng thái đăng nhập vào sessionStorage
        const authData = { username, token: fakeToken };
        sessionStorage.setItem('auth', JSON.stringify(authData));
        setAuth(authData);

        // Điều hướng đến trang quản trị
        navigate('/admin');
    };

    return (
        <div>
            <h2>Login</h2>
            <form onSubmit={handleLogin}>
                <input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Username"
                />
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                />
                <button type="submit">Login</button>
            </form>
        </div>
    );
};

export default LoginPage;
*/

import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom'; // Import Link từ react-router-dom
import { Form, Button, Alert } from 'react-bootstrap';
import api from '../../api/api';
import { useAuth } from '../../context/AuthContext';  // Import useAuth từ AuthContext

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { auth, setAuth, loading } = useAuth();  // Thêm loading từ useAuth

  useEffect(() => {
    if (!loading && auth) {  // Kiểm tra nếu `auth` đã tồn tại và không còn đang tải
      navigate('/admin/dashboard');  // Điều hướng thẳng đến trang /admin nếu đăng nhập thành công
    }
  }, [auth, loading, navigate]);  // Thêm loading vào dependency

  const handleLogin = async (event) => {
    event.preventDefault();

    try {
      const response = await api.post('/users/login', { username, password });
      const data = response.data;
      const decodedToken = parseJwt(data.token);  // Giải mã JWT
      const authData = {
        token: data.token,
        roles: decodedToken.roles,
        user: {
          _id: decodedToken.id,
          username: decodedToken.username
        }
      };

      sessionStorage.setItem('auth', JSON.stringify(authData));
      localStorage.setItem('username', username);
      localStorage.setItem('roles', authData.roles);

      setAuth(authData);  // Đặt trạng thái đăng nhập

      // Điều hướng thẳng đến trang quản trị
      
      navigate('/manage');
      
    } catch (error) {
      console.error("Login error:", error);
      setError("Login failed. Please check your username and password.");
    }
  };

  // Hàm giải mã JWT
  function parseJwt(token) {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
    } catch (e) {
      console.error("Failed to parse JWT:", e);
      return {};
    }
  }

  if (loading) {
    return <div>Loading...</div>;  // Hiển thị loading khi đang kiểm tra trạng thái đăng nhập
  }

  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <Form onSubmit={handleLogin} className="p-4 border rounded">
        <h2>Login</h2>
        {error && <Alert variant="danger">{error}</Alert>}
        <Form.Group>
          <Form.Label>Username</Form.Label>
          <Form.Control
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </Form.Group>
        <Button type="submit" variant="primary">Login</Button>
        {/* Thêm link Quên mật khẩu */}
        <div style={{ marginTop: '10px' }}>
          <Link to="/forgot-password">Quên mật khẩu?</Link>
        </div>
      </Form>
    </div>
  );
};

export default LoginPage;