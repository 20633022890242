import React, { useState, useEffect } from 'react';
import axios from './../../../api/api';
import { Button, Form, Table } from 'react-bootstrap';

const ClubListPage = () => {
    const [clubs, setClubs] = useState([]);
    const [statusFilter, setStatusFilter] = useState(''); // For filtering clubs by status

    useEffect(() => {
        fetchClubs();
    }, [statusFilter]);

    const fetchClubs = async () => {
        const response = await axios.get('/club-registrations', {
            params: { status: statusFilter } // Pass the status as a query parameter
        });
        setClubs(response.data);
    };

    const updateClubStatus = async (id, status) => {
        await axios.put(`/club-registrations/${id}`, { status });
        fetchClubs(); // Refresh the club list
    };

    const rejectClub = (id) => {
        updateClubStatus(id, 'Trả lại hồ sơ');
    };

    const acceptClub = (id) => {
        updateClubStatus(id, 'Được chấp nhận');
    };

    return (
        <div>
            <h2 className="my-4">Club Management</h2>

            {/* Filter by Status */}
            <Form.Group className="mb-3">
                <Form.Label>Filter by Status</Form.Label>
                <Form.Control
                    as="select"
                    value={statusFilter}
                    onChange={(e) => setStatusFilter(e.target.value)}
                >
                    <option value="">All</option>
                    <option value="Đề nghị thành lập CLB">Đề nghị thành lập CLB</option>
                    <option value="Trả lại hồ sơ">Trả lại hồ sơ</option>
                    <option value="Được chấp nhận">Được chấp nhận</option>
                </Form.Control>
            </Form.Group>

            <h3>Club Registrations</h3>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Club Name</th>
                        <th>Location</th>
                        <th>avatar</th>
                        <th>managerName</th>
                        <th>managerTitle</th>
                        <th>managerEmail</th>
                        <th>managerPhoneNumber</th>
                        <th>foundedDate</th>
                        <th>President</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {clubs.map((club) => (
                        <tr key={club._id}>
                            <td>{club.clubName}</td>
                            <td>{club.location}</td>
                            <td>
                                <img src={`${process.env.REACT_APP_API_BASE_URL}/${club.avatar}`} alt="Avatar" width="100" />
                            </td>
                            <td>{club.managerName}</td>
                            <td>{club.managerTitle}</td>
                            <td>{club.managerEmail}</td>
                            <td>{club.managerPhoneNumber}</td>
                            <td>{club.managerPhoneNumber}</td>
                            <td>{club.foundedDate ? new Date(club.foundedDate).toLocaleDateString('vi-VN') : 'N/A'}</td>
                            <td>{club.status}</td>
                            <td>
                                {/* Điều kiện hiển thị nút dựa trên trạng thái của hồ sơ */}
                                {club.status === 'Đề nghị thành lập CLB' && (
                                    <>
                                        <Button
                                            variant="danger"
                                            className="me-2"
                                            onClick={() => rejectClub(club._id)}
                                        >
                                            Từ chối
                                        </Button>
                                        <Button
                                            variant="success"
                                            onClick={() => acceptClub(club._id)}
                                        >
                                            Chấp nhận
                                        </Button>
                                    </>
                                )}
                                {club.status === 'Trả lại hồ sơ' && (
                                    <Button
                                        variant="success"
                                        onClick={() => acceptClub(club._id)}
                                    >
                                        Chấp nhận
                                    </Button>
                                )}
                                {/* Nếu trạng thái là Được chấp nhận thì không hiển thị nút */}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export default ClubListPage;
