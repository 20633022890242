import React from 'react';
import './Intro.css';

function Intro() {
    return (
        <section className="intro">
            <h2>Chào mừng đến với PickleHub - Nơi kết nối cộng đồng Pickleball Việt Nam!</h2>
            <p>PickleHub là nền tảng hàng đầu kết nối những người yêu thích Pickleball. Tại đây, bạn có thể dễ dàng tìm kiếm các dịch vụ huấn luyện viên, trọng tài, đặt sân thi đấu, và tham gia các trận giao lưu với cộng đồng người chơi khắp cả nước. Ngoài ra, chúng tôi còn cung cấp hệ thống xếp hạng và tính điểm chuyên nghiệp, giúp bạn theo dõi tiến trình và nâng cao kỹ năng.</p>

            <p>Hãy tham gia PickleHub để trải nghiệm trọn vẹn thế giới Pickleball và kết nối với những người đam mê như bạn!</p>
        </section>
    );
}

export default Intro;
